import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {SearchCTBodyParams, SearchCTDataProps} from '../models';

export interface ClinicalState {
  searchId: string;
  total: number;
  nbDisplayed: number;
  nbRemove: number;
  filters: SearchCTBodyParams;
  data: SearchCTDataProps;
  modalInfoShown: boolean;
}

const initialState: ClinicalState = {
  searchId: '',
  total: 0,
  nbDisplayed: 0,
  nbRemove: 0,
  modalInfoShown: false,
  filters: {
    all_conditions: [],
    overall_status: [],
    processed_phase: [],
    gender: [],
    age_group: [],
    published_results: [],
    lead_sponsor_funding: [],
    healthy_volunteers: [],
  },
  data: {
    search_id: '',
    limit: 0,
    total: 0,
    skip: 0,
    results: {phase1: [], phase2: [], phase3: [], phase4: []},
  },
};

export const clinicalSlice = createSlice({
  name: 'clinical',
  initialState,
  reducers: {
    setSearchId(state, action: PayloadAction<string>) {
      state.searchId = action.payload;
    },
    setFilters(state, action: PayloadAction<SearchCTBodyParams>) {
      state.filters = {
        ...action.payload,
        overall_status:
          action.payload.overall_status.length === 0
            ? ['- Select All -']
            : action.payload.overall_status,
      };
    },
    setData(state, action: PayloadAction<SearchCTDataProps>) {
      const {phase1, phase2, phase3, phase4} = action.payload.results;
      state.data = action.payload;
      const nbDisplayed =
        (phase1 !== undefined ? phase1.length : 0) +
        (phase2 !== undefined ? phase2.length : 0) +
        (phase3 !== undefined ? phase3.length : 0) +
        (phase4 !== undefined ? phase4.length : 0);
      state.nbDisplayed = nbDisplayed;
      state.nbRemove = 0;
      state.data.skip = 10;
    },
    changeDataCT(state, action: PayloadAction<SearchCTDataProps>) {
      state.data = action.payload;
    },
    removeCT(state, action: PayloadAction<SearchCTDataProps>) {
      state.data = action.payload;
      state.nbRemove++;
    },
    setMoreData(state, action: PayloadAction<SearchCTDataProps>) {
      const {phase1, phase2, phase3, phase4} = state.data.results;
      state.data.results.phase1 =
        action.payload.results.phase1 !== undefined
          ? phase1 === undefined
            ? action.payload.results.phase1
            : phase1.concat(action.payload.results.phase1)
          : phase1;
      state.data.results.phase2 =
        action.payload.results.phase2 !== undefined
          ? phase2 === undefined
            ? action.payload.results.phase2
            : phase2.concat(action.payload.results.phase2)
          : phase2;
      state.data.results.phase3 =
        action.payload.results.phase3 !== undefined
          ? phase3 === undefined
            ? action.payload.results.phase3
            : phase3.concat(action.payload.results.phase3)
          : phase3;
      state.data.results.phase4 =
        action.payload.results.phase4 !== undefined
          ? phase4 === undefined
            ? action.payload.results.phase4
            : phase4.concat(action.payload.results.phase4)
          : phase4;
      state.data.skip = state.data.skip + 10;
      const nbDisplayed =
        (action.payload.results.phase1 !== undefined ? action.payload.results.phase1.length : 0) +
        (action.payload.results.phase2 !== undefined ? action.payload.results.phase2.length : 0) +
        (action.payload.results.phase3 !== undefined ? action.payload.results.phase3.length : 0) +
        (action.payload.results.phase4 !== undefined ? action.payload.results.phase4.length : 0);
      state.nbDisplayed += nbDisplayed;
    },
    setConditions(state, action: PayloadAction<string[]>) {
      state.filters.all_conditions = action.payload;
    },
    setStatus(state, action: PayloadAction<string[]>) {
      state.filters.overall_status = action.payload;
    },
    setPhases(state, action: PayloadAction<string[]>) {
      state.filters.processed_phase = action.payload;
    },
    setGender(state, action: PayloadAction<string[]>) {
      state.filters.gender = action.payload;
    },
    setAge(state, action: PayloadAction<string[]>) {
      state.filters.age_group = action.payload;
    },
    setStudy(state, action: PayloadAction<string[]>) {
      state.filters.published_results = action.payload;
    },
    setFunderType(state, action: PayloadAction<string[]>) {
      state.filters.lead_sponsor_funding = action.payload;
    },
    setHealthyVolunteers(state, action: PayloadAction<string[]>) {
      state.filters.healthy_volunteers = action.payload;
    },
    reset: () => initialState,
    setModalInfo(state, action: PayloadAction<boolean>) {
      state.modalInfoShown = action.payload;
    },
  },
});
export const {
  setSearchId,
  setFilters,
  setData,
  setConditions,
  setStatus,
  setAge,
  setPhases,
  setFunderType,
  setGender,
  setStudy,
  setHealthyVolunteers,
  reset,
  setMoreData,
  removeCT,
  changeDataCT,
  setModalInfo,
} = clinicalSlice.actions;
export default clinicalSlice.reducer;
