import {
  BORDER_RADIUS,
  BORDER_WIDTH,
  BACKGROUND_DARK,
  GREY_2,
  GREY_1,
  WHITE,
  LIGHT_GREY_1,
  BLUE_7_SECONDARY_DARK,
  BLUE_3_SECONDARY,
  DARK_HALEX,
  SHADOW_HALEX,
} from '@devipsendigital/react-ui-kit';
import {DefaultTheme} from 'styled-components';

import {
  BLUE_BUTTON_LIGHT,
  BLUE_LIGHT_HALEX_DARK,
  BLUE_BUTTON_LIGHT_HOVER_LIGHT,
  BLUE_LIGHT_HALEX,
  DARK_GREY_1,
  BLUE_LIGHT_HALEX_LIGHT,
} from '../colors';

export const DARK_THEME: DefaultTheme = {
  name: 'dark',
  borderRadius: BORDER_RADIUS,
  borderWidth: BORDER_WIDTH,
  fontBase: 'Roboto',
  colors: {
    background: BACKGROUND_DARK,
    textColor: WHITE,
    headerBackground: GREY_1,
    logo: WHITE,
  },
  button: {
    colors: {
      normal: BLUE_LIGHT_HALEX_DARK,
      hover: BLUE_LIGHT_HALEX,
      disabled: LIGHT_GREY_1,
      textColor: WHITE,
    },
  },
  switch: {
    toggle: {
      colors: {
        normal: BLUE_LIGHT_HALEX_DARK,
      },
    },
  },
  inputChip: {
    colors: {
      normal: BLUE_7_SECONDARY_DARK,
    },
  },
  inputSelect: {
    colors: {
      background: DARK_GREY_1,
    },
    control: {
      colors: {
        boxShadow: BACKGROUND_DARK,
      },
    },
    input: {
      colors: {
        textColor: WHITE,
      },
    },
  },
  step: {
    colors: {
      active: BLUE_7_SECONDARY_DARK,
      disabled: GREY_2,
    },
  },
  selectableList: {
    autocomplete: {
      checkbox: {
        checkedBackground: BLUE_BUTTON_LIGHT,
        unChecked: LIGHT_GREY_1,
      },
      hoverColor: BLUE_LIGHT_HALEX_LIGHT,
    },
    containerText: {
      background: DARK_HALEX,
      shadow: SHADOW_HALEX,
      textColor: WHITE,
    },
  },
  radio: {
    colors: {
      normal: LIGHT_GREY_1,
      hover: BLUE_3_SECONDARY,
      background: BACKGROUND_DARK,
      textColor: WHITE,
    },
  },
  table: {
    colors: {
      background: GREY_1,
      border: GREY_2,
      hoveredRow: BLUE_BUTTON_LIGHT_HOVER_LIGHT,
      headerRow: BLUE_BUTTON_LIGHT,
      leftArrowButton: LIGHT_GREY_1,
      leftArrowButtonPrevious: BLUE_LIGHT_HALEX_DARK,
      rightArrowButton: LIGHT_GREY_1,
      rightArrowButtonPrevious: BLUE_LIGHT_HALEX_DARK,
      displayPage: BLUE_BUTTON_LIGHT,
    },
    header: {
      cells: {
        background: BLUE_BUTTON_LIGHT,
      },
    },
  },
  typography: {
    p1: {
      colors: {
        textColor: GREY_2,
      },
    },
    p2: {
      colors: {
        textColor: GREY_2,
      },
    },
    p3: {
      colors: {
        textColor: GREY_2,
      },
    },
    h1: {
      colors: {
        textColor: BLUE_3_SECONDARY,
      },
    },
    h2: {
      colors: {
        textColor: BLUE_3_SECONDARY,
      },
    },
  },
};
