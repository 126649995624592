import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {IpsenThemeProvider, LIGHT_THEME} from '@devipsendigital/react-ui-kit';
import {Amplify, Auth} from 'aws-amplify';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import {AmplifyConfig} from './amplify.config';
import {useGetApiStatusQuery} from './api/apiGateway';
import {GlobalStyles} from './components/core/GlobalStyles';
import {ScrollToTop} from './components/core/ScrollToTop';
import {TopNavigation} from './components/menus/TopNavigation';
import {Modal} from './components/modals/Modal';
import {RootState} from './lib/stores/store';
import {setToken, setUserloginError} from './lib/stores/user';
import {AssetsScreen} from './screens/AssetsScreen';
import {ClinicalTrialsScreen} from './screens/clinical/ClinicalTrialsScreen';
import {HomeScreen} from './screens/home/HomeScreen';
import {MySavedSearchesScreen} from './screens/my_searches/MySavedSearchesScreen';

Amplify.configure(AmplifyConfig);

const App: React.FC = () => {
  const theme = useSelector((state: RootState) => state.theme.theme);
  const accessToken = useSelector((state: RootState) => state.user.accessToken);
  const dispatch = useDispatch();
  const {data, error} = useGetApiStatusQuery();

  useEffect(() => {
    Auth.currentSession().then(
      session => {
        const idToken = session.getIdToken().getJwtToken();
        const jwt = session.getAccessToken().getJwtToken();
        dispatch(setToken({accessToken: jwt, idToken}));
      },
      error => {
        dispatch(setUserloginError(error));
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Cognito,
        });
      }
    );
  }, [data, dispatch, error]);

  return (
    <IpsenThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        {accessToken !== '' && (
          <>
            <ToastContainer theme={theme === LIGHT_THEME ? 'light' : 'dark'} />
            <Modal />
            <TopNavigation />
            <Page>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/assets" element={<AssetsScreen />}>
                  <Route path=":searchId" element={<AssetsScreen />} />
                </Route>
                <Route path="/clinical-trials" element={<ClinicalTrialsScreen />}>
                  <Route path=":searchId" element={<ClinicalTrialsScreen />} />
                </Route>
                <Route path="/my-saved-searches" element={<MySavedSearchesScreen />}>
                  <Route path=":subPage" element={<MySavedSearchesScreen />} />
                </Route>
              </Routes>
            </Page>
          </>
        )}
      </Router>
    </IpsenThemeProvider>
  );
};

export default App;

const Page = styled.div`
  position: absolute;
  top: 80px;
  right: 0;
  left: 0;
  min-height: calc(100% - 80px);
  overflow: auto;
  padding-bottom: 32px;
  color: #ffffff;
`;
