import {HTMLProps, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {ThemeProps} from '../../lib/models';
import {BLUE_BUTTON_LIGHT, BLUE_LIGHT_HALEX, GRAY_4} from '../../colors';
import {RootState} from '../../lib/stores/store';
import {Check} from '../../svg/check';
import {Checked} from '../../svg/checked';

export const IndeterminateCheckbox = ({
  indeterminate,
  className = '',
  ...rest
}: {indeterminate?: boolean} & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  const theme = useSelector((state: RootState) => state.theme.theme as ThemeProps);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <Wrapper style={{cursor: 'pointer'}}>
      <input type="checkbox" ref={ref} className={className + ' cursor-pointer'} {...rest} hidden />
      <span>
        {rest.checked ? (
          <Checked
            color={theme.name === 'light' ? BLUE_BUTTON_LIGHT : BLUE_LIGHT_HALEX}
            size={27}
          />
        ) : (
          <Check color={GRAY_4} size={27} />
        )}
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.label`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
