import {CustomSvg} from '../lib/react';

interface CheckProps {
  size: number;
  color?: string;
}

export const Checked: CustomSvg<CheckProps> = ({size, color}) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
    <rect x="1" y="1" width="18" height="18" rx="9" stroke={color} strokeWidth="2" />
    <path
      d="M7.39246 11.9964L6.65086 12.6673L7.39246 11.9964ZM4.713 10.525L6.65086 12.6673L8.13405 11.3256L6.19618 9.18334L4.713 10.525ZM11.1004 12.6673L16.1962 7.03413L14.713 5.69243L9.61724 11.3256L11.1004 12.6673ZM6.65086 12.6673C7.84187 13.9839 9.90942 13.9839 11.1004 12.6673L9.61724 11.3256C9.22023 11.7644 8.53105 11.7644 8.13405 11.3256L6.65086 12.6673Z"
      fill={color}
    />
  </svg>
);
