import {P2, Text} from '@devipsendigital/react-ui-kit';
import {FC, Fragment, ReactNode, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {DefaultTheme} from 'styled-components';

import {isObjectEmpty} from '../../api/api_parsers';
import {
  BLUE_BUTTON_LIGHT,
  DARK_GREY_1,
  DARK_GREY_2,
  DARK_GREY_3,
  PINK_BRIGHT_LIGHT,
} from '../../colors';
import {PhaseInfo} from '../../lib/models';
import {changeDataCT} from '../../lib/stores/clinical';
import {hideModal, showModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';
import {DeleteBox} from '../../svg/deleteBox';
import {ExitFromPhase} from '../../svg/exitFromPhase';
import {Icon} from '../../svg/icon';
import {Pin} from '../../svg/pin';
import {StageEditInfo} from '../clinical/StageEditInfo';
import {Spacing} from '../core/Spacing';

import {ModalActions, ModalContentForPhase, ModalTitle, WrapperExit} from './ModalFragments';

interface ThemeProps {
  theme: DefaultTheme;
  children: ReactNode;
  name: string;
}

export const DisplayStageInfoModal: FC = () => {
  const dispatch = useDispatch();
  const {show, param} = useSelector((state: RootState) => state.modal);
  const {search_id, results, total, skip} = useSelector((state: RootState) => state.clinical).data;
  const [stageInfo, setStageInfo] = useState<PhaseInfo>();

  const theme = useSelector((state: RootState) => state.theme.theme as ThemeProps);

  const [isInterventionClicked, setInterventionIsClicked] = useState(false);
  const [isCompanyNameClicked, setCompanyNameIsClicked] = useState(false);
  const [isConditionClicked, setConditionIsClicked] = useState(false);

  const [phaseInfoInterventions, editPhaseInfoInterventions] = useState('');
  const [phaseInfoCompanyName, editPhaseInfoCompanyName] = useState('');
  const [phaseInfoConditions, editPhaseInfoConditions] = useState('');

  const handleEntryClick = async (e: any) => {
    if (e.currentTarget.id === 'interventions') {
      setInterventionIsClicked(!isInterventionClicked);
      stageInfo && editPhaseInfoInterventions(stageInfo.interventions);
    } else if (e.currentTarget.id === 'companyName') {
      setCompanyNameIsClicked(!isCompanyNameClicked);
      stageInfo && editPhaseInfoCompanyName(stageInfo.lead_sponsor);
    } else if (e.currentTarget.id === 'conditions') {
      setConditionIsClicked(!isConditionClicked);
      stageInfo && editPhaseInfoConditions(stageInfo.conditions);
    } else {
      setInterventionIsClicked(false);
      setCompanyNameIsClicked(false);
      setConditionIsClicked(false);
      editPhaseInfoInterventions('');
      editPhaseInfoCompanyName('');
      editPhaseInfoConditions('');
    }
  };

  const handleEditChangeInterventions = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      const value = e.currentTarget.value;
      editPhaseInfoInterventions(value.trim().length === 0 ? '' : value);
    },
    [editPhaseInfoInterventions]
  );

  const handleEditChangeCompanyName = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      const value = e.currentTarget.value;
      editPhaseInfoCompanyName(value.trim().length === 0 ? '' : value);
    },
    [editPhaseInfoCompanyName]
  );

  const handleEditChangeConditions = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      const value = e.currentTarget.value;
      editPhaseInfoConditions(value.trim().length === 0 ? '' : value);
    },
    [editPhaseInfoConditions]
  );

  const handleValidateEntryClickInt = () => {
    setInterventionIsClicked(false);
    const arrCopyResults = {...results};
    if (isObjectEmpty(results) !== 0 && param?.body !== '') {
      for (let phase in results) {
        let phaseContent = results[phase as keyof typeof results];
        if (phaseContent !== undefined) {
          const myPhaseTemp = phaseContent.map(element => {
            if (element.nct_identifier === param?.body) {
              return {...element, interventions: phaseInfoInterventions};
            } else {
              return {...element};
            }
          });
          arrCopyResults[phase as keyof typeof arrCopyResults] = myPhaseTemp;
        }
      }
      if (isObjectEmpty(arrCopyResults) !== 0) {
        dispatch(changeDataCT({search_id, limit: 10, skip, total, results: arrCopyResults}));
      }
    }
    return () => {};
  };

  const handleValidateEntryClickCN = () => {
    setCompanyNameIsClicked(false);
    const arrCopyResults = {...results};
    if (isObjectEmpty(results) !== 0 && param?.body !== '') {
      for (let phase in results) {
        let phaseContent = results[phase as keyof typeof results];
        if (phaseContent !== undefined) {
          const myPhaseTemp = phaseContent.map(element => {
            if (element.nct_identifier === param?.body) {
              return {...element, lead_sponsor: phaseInfoCompanyName};
            } else {
              return {...element};
            }
          });
          arrCopyResults[phase as keyof typeof arrCopyResults] = myPhaseTemp;
        }
      }
      if (isObjectEmpty(arrCopyResults) !== 0) {
        dispatch(changeDataCT({search_id, limit: 10, total, skip, results: arrCopyResults}));
      }
    }
    return () => {};
  };

  const handleValidateEntryClickCOND = () => {
    setConditionIsClicked(false);
    const arrCopyResults = {...results};
    if (isObjectEmpty(results) !== 0 && param?.body !== '') {
      for (let phase in results) {
        let phaseContent = results[phase as keyof typeof results];
        if (phaseContent !== undefined) {
          const myPhaseTemp = phaseContent.map(element => {
            if (element.nct_identifier === param?.body) {
              return {...element, conditions: phaseInfoConditions};
            } else {
              return {...element};
            }
          });
          arrCopyResults[phase as keyof typeof arrCopyResults] = myPhaseTemp;
        }
      }
      if (isObjectEmpty(arrCopyResults) !== 0) {
        dispatch(changeDataCT({search_id, limit: 10, skip, total, results: arrCopyResults}));
      }
    }
    return () => {};
  };

  useEffect(() => {
    if (isObjectEmpty(results) !== 0 && param?.body !== '') {
      Object.keys(results).forEach((phase: string) => {
        const phaseContent = results[phase as keyof typeof results];
        if (phaseContent !== undefined) {
          const elem = phaseContent.find(
            (phaseContentInfo: {nct_identifier: string | undefined}) => {
              return phaseContentInfo.nct_identifier === param?.body;
            }
          ) as PhaseInfo;
          if (elem !== undefined) {
            setStageInfo(elem);
          }
        }
      });
    }
  }, [results, param]);

  const handleHideModal = useCallback(() => {
    if (show === true) {
      setInterventionIsClicked(false);
      setCompanyNameIsClicked(false);
      setConditionIsClicked(false);
      dispatch(hideModal());
    }
  }, [dispatch, show]);

  useEffect(() => {
    if (show === false && isInterventionClicked === true) {
      setInterventionIsClicked(false);
      dispatch(hideModal());
    }
    if (show === false && isCompanyNameClicked === true) {
      setCompanyNameIsClicked(false);
      dispatch(hideModal());
    }
    if (show === false && isConditionClicked === true) {
      setConditionIsClicked(false);
      dispatch(hideModal());
    }
  }, [dispatch, isCompanyNameClicked, isConditionClicked, isInterventionClicked, show]);

  const handleDeleteEntryClick = async () => {
    dispatch(showModal({modalType: 'delete-phase-info', param}));
  };

  return (
    <Fragment>
      <ModalContentForPhase>
        <ModalActions>
          <ModalTitle>Clinical Asset Description</ModalTitle>
          <WrapperExit>
            <ExitFromPhase color={DARK_GREY_3} size={25} onClick={handleHideModal} />
          </WrapperExit>
        </ModalActions>
        <Spacing height={20} />
        <TextBlock>
          <Text color="light-grey" size="small" weight="light">
            Intervention
          </Text>
        </TextBlock>
        <StageEditInfo
          title=""
          subtitles={stageInfo && stageInfo.interventions}
          handleEditEntryClick={handleEntryClick}
          isInterventionClicked={isInterventionClicked}
          reference="interventions"
          phaseInfo={phaseInfoInterventions}
          handleEditChange={handleEditChangeInterventions}
          handleValidateEntryClick={handleValidateEntryClickInt}
        ></StageEditInfo>
        <Spacing height={10} />
        <TextBlock>
          <Text color="light-grey" size="small" weight="light">
            Sponsor
          </Text>
        </TextBlock>
        <StageEditInfo
          title=""
          subtitles={stageInfo && stageInfo.lead_sponsor}
          handleEditEntryClick={handleEntryClick}
          isCompanyNameClicked={isCompanyNameClicked}
          reference="companyName"
          phaseInfo={phaseInfoCompanyName}
          handleEditChange={handleEditChangeCompanyName}
          handleValidateEntryClick={handleValidateEntryClickCN}
        ></StageEditInfo>
        <Spacing height={20} />
        <WrapperText>
          <TextBlock>
            <Pin color={DARK_GREY_3} size={20} />
            <Text color="light-grey" size="small" weight="light">
              Identification number
            </Text>
          </TextBlock>
          <P2 color={BLUE_BUTTON_LIGHT}>
            <a target="_blank" href={`${stageInfo && stageInfo.url}`} rel="noreferrer">
              {stageInfo && stageInfo.nct_identifier}
            </a>
          </P2>
        </WrapperText>
        <Spacing height={20} />
        <WrapperText>
          <TextBlock>
            <Text color="light-grey" size="small" weight="light">
              Phase reference
            </Text>
          </TextBlock>
          <P2 color={theme.name === 'light' ? DARK_GREY_1 : DARK_GREY_2}>
            {stageInfo && stageInfo.phase}
          </P2>
        </WrapperText>
        <Spacing height={20} />
        <WrapperText>
          <TextBlock>
            <Text color="light-grey" size="small" weight="light">
              {stageInfo && stageInfo.primary_completion_date_actual !== '01/1970'
                ? 'Actual Primary Completion Date'
                : stageInfo && stageInfo.primary_completion_date_anticipated !== '01/1970'
                ? 'Estimated Primary Completion Date'
                : 'Primary completion Date: '}
            </Text>
          </TextBlock>
          <P2 color={theme.name === 'light' ? DARK_GREY_1 : DARK_GREY_2}>
            {stageInfo && stageInfo.primary_completion_date_actual !== '01/1970'
              ? stageInfo.primary_completion_date_actual
              : stageInfo && stageInfo.primary_completion_date_anticipated !== '01/1970'
              ? stageInfo.primary_completion_date_anticipated
              : 'not available'}
          </P2>
        </WrapperText>
        <Spacing height={20} />
        <WrapperText>
          <TextBlock>
            <Icon color={DARK_GREY_3} size={20}></Icon>
            <Spacing width={5} />
            <Text color="light-grey" size="small" weight="light">
              Condition or Disease
            </Text>
          </TextBlock>
          <StageEditInfo
            title=""
            subtitles={stageInfo && stageInfo.conditions}
            handleEditEntryClick={handleEntryClick}
            isConditionClicked={isConditionClicked}
            reference="conditions"
            phaseInfo={phaseInfoConditions}
            handleEditChange={handleEditChangeConditions}
            handleValidateEntryClick={handleValidateEntryClickCOND}
          ></StageEditInfo>
        </WrapperText>
        <Spacing height={10} />
        <Line></Line>
        <Spacing height={10} />
        <WrapperDelete onClick={handleDeleteEntryClick}>
          <DeleteBox color={PINK_BRIGHT_LIGHT} size={20}></DeleteBox>
          <P2 color={PINK_BRIGHT_LIGHT}>Delete entry</P2>
        </WrapperDelete>
      </ModalContentForPhase>
    </Fragment>
  );
};

const TextBlock = styled.div`
  color: ${({theme}) => theme.colors.textColor};
  display: flex;
  width: 100%;
`;

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const WrapperDelete = styled.div`
  display: flex;
  flex-direction: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`;

const Line = styled.div`
  border: 1px solid #c6c9d2;
  width: 100%;
`;
