import {BLUE_4_SECONDARY_DARK, Button, H1, P3, WHITE} from '@devipsendigital/react-ui-kit';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import {isObjectEmpty} from '../../api/api_parsers';
import {DARK_GREY_1} from '../../colors';
import {ClinicalFilters} from '../../components/clinical/ClinicalFilters';
import {ClinicalResults} from '../../components/clinical/ClinicalResults';
import {DropdownButton} from '../../components/core/DropdownButton';
import {savedSearches} from '../../components/modals/SaveSearchesModal';
import {ThemeProps} from '../../lib/models';
import {setSearchId} from '../../lib/stores/clinical';
import {showModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';

export const ClinicalTrialsScreen: React.FC = () => {
  const theme = useSelector((state: RootState) => state.theme.theme as ThemeProps);

  const dispatch = useDispatch();

  const {data: dataStore} = useSelector((state: RootState) => state.clinical);
  const data = dataStore.results;

  const handleSaveClick = async () => {
    dispatch(setSearchId(dataStore.search_id));
    dispatch(showModal({modalType: 'saved-search', param: {type: savedSearches.SAVED_CT}}));
  };

  const {searchId} = useParams();

  return (
    <Wrapper>
      <WrapperSystem>
        <WrapperTitle>
          <H1 color={theme.name === 'light' ? DARK_GREY_1 : WHITE}>
            New Clinical Trials Landscape
          </H1>

          {isObjectEmpty(data) !== 0 && Object.keys(data).length > 0 && (
            <DownloadButton
              button={
                <ButtonDownload
                  icon="save"
                  onClick={handleSaveClick}
                  size="medium"
                  typeButton="secondary"
                  borderDisabled
                >
                  Save search
                </ButtonDownload>
              }
              options={[]}
            />
          )}
        </WrapperTitle>
      </WrapperSystem>
      <Note color={theme.name === 'light' ? DARK_GREY_1 : WHITE}>
        Note: All clinical trials are interventional study type
      </Note>

      <ClinicalFilters clinicalId={searchId} />

      <ClinicalResults></ClinicalResults>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 40px 70px 0 70px;
  height: 100%;
`;

const WrapperSystem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  @media print {
    display: none;
  }
`;

const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Note = styled(P3)`
  margin-bottom: 20px;
`;

const ButtonDownload = styled(Button)`
  border: none;
  @media print {
    display: none;
  }
`;

const DownloadButton = styled(DropdownButton)`
  color: ${BLUE_4_SECONDARY_DARK};
  @media print {
    display: none;
  }
`;
