export const AmplifyConfig = {
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ['email', 'openid', 'profile', 'phone'],
      redirectSignIn: `${process.env.REACT_APP_REDIRECT_URI}/`,
      redirectSignOut: `${process.env.REACT_APP_REDIRECT_URI}/`,
      responseType: 'code',
    },
  },
};
