import {Button} from '@devipsendigital/react-ui-kit';
import {FC, Fragment, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {isObjectEmpty} from '../../api/api_parsers';
import {PINK_BRIGHT_LIGHT} from '../../colors';
import {removeCT} from '../../lib/stores/clinical';
import {hideModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';
import {Spacing} from '../core/Spacing';
import {Text} from '../core/Typography';

import {ModalActions} from './ModalFragments';

interface DeletePhaseFormProps {
  onSuccess: () => void;
  onFailure: (err: Error) => void;
  param: string;
}

export const DeletePhaseForm: FC<DeletePhaseFormProps> = props => {
  const {onSuccess, onFailure, param} = props;

  const {search_id, total, results, limit, skip} = useSelector(
    (state: RootState) => state.clinical
  ).data;

  const dispatch = useDispatch();
  const {show} = useSelector((state: RootState) => state.modal);

  const handleHideModal = useCallback(() => {
    if (show === true) {
      dispatch(hideModal());
    }
  }, [dispatch, show]);

  const handleDeleteClusteringClick = useCallback(() => {
    // remove phase from view
    const resultsForUpdatedPhases = {} as typeof results;
    if (isObjectEmpty(results) !== 0 && param !== '') {
      Object.keys(results).forEach((phase: string) => {
        const phaseContent = results[phase as keyof typeof results];
        if (phaseContent !== undefined) {
          const temp = phaseContent.filter(phaseContentElement => {
            return phaseContentElement.nct_identifier !== param;
          });
          resultsForUpdatedPhases[phase as keyof typeof results] = temp;
        }
      });
    }
    if (isObjectEmpty(resultsForUpdatedPhases) !== 0) {
      dispatch(
        removeCT({
          search_id,
          limit,
          skip: skip,
          total: total,
          results: resultsForUpdatedPhases,
        })
      );
      return onSuccess();
    } else {
      return onFailure;
    }
  }, [dispatch, limit, onFailure, onSuccess, param, results, search_id, skip, total]);

  return (
    <Fragment>
      <Spacing height={32} />
      <Text weight="bold"></Text>
      <Spacing height={32} />
      <ModalActions>
        <Button typeButton="secondary" onClick={handleHideModal} size="small">
          Skip
        </Button>
        <Button
          typeButton="primary"
          color={PINK_BRIGHT_LIGHT}
          hoverColor={PINK_BRIGHT_LIGHT}
          onClick={handleDeleteClusteringClick}
          size="small"
        >
          Remove
        </Button>
      </ModalActions>
    </Fragment>
  );
};
