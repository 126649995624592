import {
  BLUE_4_SECONDARY_DARK,
  Button,
  H1,
  H2,
  P1,
  Spinner,
  WHITE,
} from '@devipsendigital/react-ui-kit';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import {
  useGetApiDownloadPostSearchResultMutation,
  useGetApiDownloadSearchResultMutation,
} from '../api/apiGateway';
import {BLUE_BUTTON_LIGHT, BLUE_LIGHT_HALEX, DARK_GREY_1, DARK_GREY_3} from '../colors';
import {DropdownButton} from '../components/core/DropdownButton';
import {DropdownSelect} from '../components/core/DropdownSelect';
import {Spacing} from '../components/core/Spacing';
import {savedSearches} from '../components/modals/SaveSearchesModal';
import {AssetsFilters} from '../components/search/AssetsFilters';
import {TableSearch} from '../components/search/TableSearch';
import {WrapperSpinner} from '../components/stylesGeneric';
import {FilterTypes, IDataSearchResult, Status, ThemeProps} from '../lib/models';
import {showModal} from '../lib/stores/modal';
import {setSearchId} from '../lib/stores/search';
import {RootState} from '../lib/stores/store';
import {Information} from '../svg/information';

export const AssetsScreen: React.FC = () => {
  const theme = useSelector((state: RootState) => state.theme.theme as ThemeProps);

  const {data} = useSelector((state: RootState) => state.search);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownload] = useState(false);

  const handleSaveClick = async () => {
    const search_id = data !== undefined && data.length > 0 ? data[0].searchId : '';
    dispatch(setSearchId(search_id));
    dispatch(showModal({modalType: 'saved-search', param: {type: savedSearches.SAVED_ASSET}}));
  };

  const [downloadPostResult] = useGetApiDownloadPostSearchResultMutation({
    fixedCacheKey: 'get-download-post-results',
  });

  const [downloadGetResult] = useGetApiDownloadSearchResultMutation({
    fixedCacheKey: 'get-download-get-results',
  });

  const statuses = [
    {status_ref: 'Global data', color: {light: BLUE_LIGHT_HALEX, dark: BLUE_BUTTON_LIGHT}},
    {status_ref: 'Cortellis', color: {light: BLUE_BUTTON_LIGHT, dark: BLUE_LIGHT_HALEX}},
  ];

  const handleDownloadClick = async () => {
    setIsDownload(true);
    if (data.length > 0) {
      const search_id = data[0].searchId;
      if (Object.keys(rowSelection).length > 0) {
        const asset_ids_exclude = data
          .filter((_val, index: number) => {
            if (!rowSelection[index as keyof typeof rowSelection]) {
              return true;
            }
            return false;
          })
          .map((elem: IDataSearchResult) => {
            return elem.assetId;
          });
        const body = {
          asset_ids_exclude,
        };
        downloadPostResult({search_id, body})
          .then(() => setIsDownload(false))
          .finally(() => setIsDownload(false));
      } else {
        downloadGetResult({search_id})
          .then(() => setIsDownload(false))
          .finally(() => setIsDownload(false));
      }
    }
  };

  const [rowSelection, setRowSelection] = React.useState({});

  const defaultValueDropdownNbPage = {
    label: '5',
    value: 'five',
  };
  const [statusNbRows, setStatusNbRows] = useState<FilterTypes>(defaultValueDropdownNbPage);

  const handleStatusChangeNbRows = useCallback(
    (statusNbRows: FilterTypes) => setStatusNbRows(statusNbRows),
    []
  );

  const statusOptionsNbRows = ['10', '25', '50', '75', '100'];

  const [statusNbRowsList, setStatusNbRowsList] = useState<string[]>(['25']);

  const handleStatusChangeNbRowsList = useCallback(
    (statusNbRowsList: string[]) => setStatusNbRowsList(statusNbRowsList),
    []
  );

  const [isShown, setIsShown] = useState(false);

  const {searchId} = useParams();

  useEffect(() => {
    if (data.length > 0) {
      const selected = {} as Record<number, boolean>;
      data.forEach((_, i) => {
        if (data[i].subRows) {
          data[i].subRows?.forEach((_, j) => {
            return (selected[`${i}.${j}` as unknown as keyof typeof selected] = true);
          });
        }
        return (selected[i as keyof typeof selected] = true);
      });
      setRowSelection(selected);
    }
    handleStatusChangeNbRows(statusNbRows);
  }, [statusNbRows, handleStatusChangeNbRows, data, searchId]);

  return (
    <Wrapper>
      <WrapperSystem>
        <H1 color={theme.name === 'light' ? DARK_GREY_1 : WHITE}>New Asset Landscape</H1>
        {data.length > 0 && (
          <DownloadButton
            button={
              <ButtonDownload
                icon="save"
                onClick={handleSaveClick}
                size="medium"
                typeButton="secondary"
                borderDisabled
              >
                Save search
              </ButtonDownload>
            }
            options={[]}
          />
        )}
      </WrapperSystem>
      <AssetsFilters searchId={searchId} setLoading={setLoading}></AssetsFilters>
      <Spacing height={10} />
      <WrapperSystem>
        <TitleTable>
          {data.length > 0 && (
            <>
              <WrapperNbSearch>{data.length}</WrapperNbSearch>
              <Spacing width={10} />
              <H2 color={theme.name === 'light' ? DARK_GREY_1 : WHITE}>Search results</H2>
              <Spacing width={10} />
              <DropDownContainer>
                <DropdownSelect
                  selected={statusNbRowsList}
                  doNotShowSelected={true}
                  onChange={handleStatusChangeNbRowsList}
                  isSingleValue={true}
                  data={statusOptionsNbRows}
                  placeholder={statusNbRowsList[0]}
                />
              </DropDownContainer>

              <Spacing width={20} />
              <P1 color={theme.name === 'light' ? DARK_GREY_1 : WHITE}> per page</P1>
              <Spacing width={10} />
              <InformationSvgContainer
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
              >
                <Information size={20} />
              </InformationSvgContainer>
              {isShown && (
                <LegendContainer>
                  <StatusLegendContainer>
                    {statuses.map((status: Status, index) => (
                      <StatusContent key={index}>
                        <ColorBlock
                          backgroundColor={
                            theme.name === 'light' ? status.color.light : status.color.dark
                          }
                        ></ColorBlock>
                        <Spacing width={5} />
                        <TextRef>{status.status_ref}</TextRef>
                      </StatusContent>
                    ))}
                  </StatusLegendContainer>
                </LegendContainer>
              )}
            </>
          )}
        </TitleTable>
        {data.length > 0 && (
          <>
            <DownloadButton
              button={
                <ButtonDownload
                  icon="download"
                  onClick={handleDownloadClick}
                  size="medium"
                  typeButton="secondary"
                  borderDisabled
                >
                  {isDownloading ? (
                    <WrapperSpinner>
                      <Spinner spinnerSize={20} spinnerThickness={3} color={BLUE_BUTTON_LIGHT} />
                    </WrapperSpinner>
                  ) : (
                    <>Download database</>
                  )}
                </ButtonDownload>
              }
              options={[]}
            />
          </>
        )}
      </WrapperSystem>
      {loading ? (
        <WrapperSpinner>
          <Spinner spinnerSize={30} spinnerThickness={3} color={WHITE} />
        </WrapperSpinner>
      ) : (
        <TableSearch
          statusNbRows={statusNbRowsList}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        ></TableSearch>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 40px 70px 0 70px;
`;

const WrapperSystem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const TitleTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WrapperNbSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 25px;
  background: ${BLUE_LIGHT_HALEX};
  border-radius: 8px;
`;

const ButtonDownload = styled(Button)`
  border: none;
`;

const DownloadButton = styled(DropdownButton)`
  color: ${BLUE_4_SECONDARY_DARK};
`;

const DropDownContainer = styled.div`
  display: flex;
  width: 100px;
`;

const InformationSvgContainer = styled.div`
  cursor: pointer;
`;

const StatusLegendContainer = styled.div`
  position: absolute;
  left: 0.5rem;
  background: ${({theme}) => theme.inputSelect.colors.background};
  border: 1px solid ${DARK_GREY_3};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 5px 10px 0;
  z-index: 1;
  width: 8rem;
  bottom: 0rem;
`;

const StatusContent = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 10px;
`;

const ColorBlock = styled.div<{backgroundColor: string}>`
  border: 5px solid ${({backgroundColor}) => backgroundColor};
  background: ${({backgroundColor}) => backgroundColor};
  height: 10px;
`;

const TextRef = styled.div`
  color: ${({theme}) => theme.colors.textColor};
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
`;

const LegendContainer = styled.div`
  position: relative;
`;
