import {BLACK, LIGHT_GREY_1, SaveSvg, WHITE} from '@devipsendigital/react-ui-kit';
import {Auth} from 'aws-amplify';
import {ReactNode, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import styled, {AnyStyledComponent, DefaultTheme} from 'styled-components';

import {BLUE_BUTTON_LIGHT, BLUE_LIGHT_HALEX, DARK_GREY_1} from '../../colors';
import {ACTIVE_MENU_CLASSNAME} from '../../lib/constants';
import useOnClickOutside from '../../lib/hooks/clickOutSide';
import {RootState} from '../../lib/stores/store';
import {Burger} from '../../svg/burger';
import {Exit} from '../../svg/exit';
import {HalexLogo} from '../../svg/halex_logo';
import {Badge} from '../core/Badge';
import {Spacing} from '../core/Spacing';

import {BurgerModal} from './BurgerModal';
interface ThemeProps {
  theme: DefaultTheme;
  children: ReactNode;
  name: string;
}

export const TopNavigation: React.FC = () => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useOnClickOutside(buttonRef, () => setDrawerOpen(false));

  const to = '/my-saved-searches';
  const isActive = to === window.location.pathname;

  const theme = useSelector((state: RootState) => state.theme.theme as ThemeProps);

  return (
    <Wrapper>
      <LeftNav>
        <Spacing width={40} />
        <HomeNavLink to="/">
          <HalexLogo size={160} color={theme.name === 'light' ? DARK_GREY_1 : WHITE}></HalexLogo>
        </HomeNavLink>
      </LeftNav>
      <CenterNav>
        <BaseNavLink to="/assets" className={ACTIVE_MENU_CLASSNAME}>
          Referential Assets
        </BaseNavLink>
        <Spacing width={80} />
        <BaseNavLink to="/clinical-trials" className={ACTIVE_MENU_CLASSNAME}>
          Referential Clinical trials
        </BaseNavLink>
      </CenterNav>
      <RightNav>
        <Spacing width={60} />
        <BaseNavLink to="/my-saved-searches" className={ACTIVE_MENU_CLASSNAME}>
          <ContainerSvg
            color={isActive ? BLUE_BUTTON_LIGHT : LIGHT_GREY_1}
            size={22}
          ></ContainerSvg>
          <Spacing width={10} />
          <Badge visible={true}> My saves</Badge>
        </BaseNavLink>
        <Spacing width={40} />
        <Drawer ref={buttonRef}>
          {!drawerOpen ? (
            <Burger
              color={BLUE_BUTTON_LIGHT}
              size={27}
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            />
          ) : (
            <Exit
              color={BLUE_BUTTON_LIGHT}
              size={25}
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            />
          )}
          {drawerOpen && (
            <DrawerOpenPopup>
              <BurgerModal
                options={[
                  {
                    title: 'Log out',
                    onClick: () => {
                      Auth.signOut();
                    },
                  },
                ]}
              />
            </DrawerOpenPopup>
          )}
        </Drawer>
        <Spacing width={40} />
      </RightNav>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: ${p => p.theme.colors.headerBackground};
  z-index: 10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media print {
    display: none;
  }
`;

const DrawerOpenPopup = styled.div``;
const Drawer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30px;
`;

const ContainerSvg = styled(SaveSvg)`
  color: ${LIGHT_GREY_1};
  size: 22;
`;

const BaseNavLink = styled(NavLink as unknown as AnyStyledComponent)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 17.58px;
  font-weight: 700;
  cursor: pointer;
  color: ${({theme}) => (theme.name === 'light' ? BLACK : WHITE)};
  text-align: center;
  border-bottom: solid 4px transparent;
  &.${ACTIVE_MENU_CLASSNAME}.active {
    color: ${({theme}) => (theme.name === 'light' ? BLACK : WHITE)};
    border-bottom-color: ${BLUE_LIGHT_HALEX};
    z-index: 0;
    &.${ContainerSvg} {
      color: ${BLUE_LIGHT_HALEX};
    }
  }
  &:hover {
    text-decoration: none;
  }
`;

const HomeNavLink = styled(BaseNavLink)`
  padding-bottom: 5px;
  &:hover {
    text-decoration: none;
  }
`;

const NavSection = styled.div`
  display: flex;
  align-items: center;
`;
const LeftNav = styled(NavSection)`
  display: flex;
  flex: 1;
`;
const RightNav = styled(NavSection)`
  flex: 1;
  display: flex;
  height: 80px;
  bottom: 0;
  right: 0.5em;
  justify-content: flex-end;
`;
const CenterNav = styled(NavSection)`
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;
`;
