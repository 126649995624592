import styled from 'styled-components';

import {Subtitle} from '../core/Typography';

export const ModalTitle = styled(Subtitle)`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: ${({theme}) => theme.colors.textColor};
  @media print {
    height: 50px;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalActions = styled.div`
  display: flex;
  width: 100%;
  gap: 5%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ModalContentForPhase = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: center;
  padding: 20px 20px 20px 20px;
`;

export const WrapperExit = styled.div`
  height: 100%;
  padding: 0 10px 0 0;
  cursor: pointer;
`;
