import {Button} from '@devipsendigital/react-ui-kit';
import React, {FC, Fragment, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {useAddSavedCTMutation, useAddSavedSearchMutation} from '../../api/apiGateway';
import {reset} from '../../lib/stores/clinical';
import {resetAssetFilters} from '../../lib/stores/search';
import {ExtraEnum, hideModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';
import {Spacing} from '../core/Spacing';
import {TextField} from '../core/TextField';
import {Text} from '../core/Typography';

import {ModalActions} from './ModalFragments';
import {savedSearches} from './SaveSearchesModal';

interface SaveSearchesFormProps {
  onSuccess: () => void;
  onFailure: (err: Error) => void;
}

export const SaveSearchesForm: FC<SaveSearchesFormProps> = props => {
  const {onSuccess, onFailure} = props;

  const [searchName, setSearchName] = useState('');

  const {searchId} = useSelector((state: RootState) => state.search);
  const {searchId: idCT} = useSelector((state: RootState) => state.clinical);

  const dispatch = useDispatch();
  const {show, param} = useSelector((state: RootState) => state.modal);

  const handleQueryNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => {
    const value = e.currentTarget.value;
    setSearchName(value.trim().length === 0 ? '' : value);
  }, []);

  useEffect(() => {
    setSearchName('');
  }, [show]);

  const handleHideModal = useCallback(() => {
    if (show === true) {
      dispatch(hideModal());
    }
    setSearchName('');
  }, [dispatch, show]);

  const handleResetModal = useCallback(() => {
    if (show === true) {
      dispatch(hideModal());
    }
    setSearchName('');
    if (param?.extra === ExtraEnum.RESET_FILTERS) {
      if (param?.type === 'ct') dispatch(reset());
      else dispatch(resetAssetFilters());
    }
  }, [dispatch, show, param]);
  const [addSearch] = useAddSavedSearchMutation();
  const [addCtSearch] = useAddSavedCTMutation();

  const handleSaveClusteringClick = useCallback(() => {
    // save the search
    if (param?.type === savedSearches.SAVED_ASSET) {
      addSearch({
        search_id: searchId,
        name: searchName,
      })
        .unwrap()
        .then(() => {
          onSuccess();
          setSearchName('');
          dispatch(reset());
        })
        .catch(onFailure);
    } else if (param?.type === savedSearches.SAVED_CT) {
      addCtSearch({
        search_id: idCT,
        name: searchName,
      })
        .unwrap()
        .then(() => {
          onSuccess();
          setSearchName('');
          dispatch(reset());
        })
        .catch(onFailure);
    }
  }, [
    param?.type,
    addSearch,
    searchId,
    searchName,
    onFailure,
    onSuccess,
    addCtSearch,
    idCT,
    dispatch,
  ]);

  return (
    <>
      {param?.extra !== ExtraEnum.RESET_FILTERS && (
        <>
          <Spacing height={32} />
          <Text weight="bold"></Text>
          <Spacing height={32} />
          {param?.body !== undefined && (
            <>
              <Body>{param?.body}</Body>
              <Spacing height={32} />
            </>
          )}

          <TextField
            value={searchName}
            onChange={handleQueryNameChange}
            placeholder="Name of your search"
          />
        </>
      )}
      <Spacing height={72} />
      <ModalActions>
        <Button typeButton="secondary" onClick={handleHideModal} size="small">
          Skip
        </Button>
        {searchName.length > 0 ? (
          <Button typeButton="primary" onClick={handleSaveClusteringClick} size="small">
            Save
          </Button>
        ) : (
          <Fragment />
        )}
        {param?.extra === ExtraEnum.RESET_FILTERS && (
          <Button typeButton="primary" onClick={handleResetModal} size="small">
            Reset
          </Button>
        )}
      </ModalActions>
    </>
  );
};

export const Body = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  /* #2 Grey */

  color: #7a7d88;
`;
