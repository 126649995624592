import {BLACK, GREY_1, GREY_2, Switch, WHITE} from '@devipsendigital/react-ui-kit';
import {MouseEventHandler, ReactNode, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {DefaultTheme} from 'styled-components';

import {CustomDiv} from '../../lib/react';
import {RootState} from '../../lib/stores/store';
import {applyTheme} from '../../lib/stores/theme';
import {DARK_THEME} from '../../theme/dark';
import {LIGHT_THEME} from '../../theme/light';
import {Spacing} from '../core/Spacing';
import {Footer} from '../Footer';
interface NavListOption {
  title: string;
  onClick?: MouseEventHandler;
}

interface ThemeProps {
  theme: DefaultTheme;
  children: ReactNode;
  name: string;
}

interface NavListProps {
  options: NavListOption[];
}

export const BurgerModal: CustomDiv<NavListProps> = ({options}) => {
  const theme = useSelector((state: RootState) => state.theme.theme as ThemeProps);
  const [darkMode, setdarkMode] = useState(theme.name === 'dark');
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <Content>
        <>
          {options.map(({title, onClick}) => (
            <DropdownItem key={title} onClick={onClick}>
              <DropdownItemText>{title}</DropdownItemText>
            </DropdownItem>
          ))}
        </>
        <Spacing height={15} />
        <ContainerSwitch>
          <ContainerItem>{!darkMode ? 'Light mode' : 'Dark mode'}</ContainerItem>
          <Switch
            checked={darkMode}
            onClick={() => {
              setdarkMode(!darkMode);
              dispatch(applyTheme(darkMode ? LIGHT_THEME : DARK_THEME));
            }}
          />
        </ContainerSwitch>
        <Footer />
        <Spacing height={15} />
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 210px;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 1em;
  top: 5em;
  right: 1.875em;
`;

const Content = styled.div`
  background: ${({theme}) => theme.colors.headerBackground};
  box-shadow: 0px 2px 8px ${GREY_1};
  border-radius: 8px;
`;

const DropdownItem = styled.div`
  padding: 30px 30px 0px 30px;
  cursor: pointer;
`;

const ContainerItem = styled.div`
  padding: 0px 10px 0px 10px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.colors.textColor};
`;

const DropdownItemText = styled.div`
  font-weight: 700;
  color: ${({theme}) => (theme.name === 'light' ? BLACK : WHITE)};
  font-style: normal;
  font-size: 15px;
  line-height: 17.58px;
  padding-left: 27%;
`;

const ContainerSwitch = styled.div`
  height: 77px;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-top: 1px solid ${GREY_2};
`;
