import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DefaultTheme} from 'styled-components';

import {LIGHT_THEME} from '../../theme/light';

export interface ThemeState {
  theme: DefaultTheme;
}

const initialState: ThemeState = {
  theme: LIGHT_THEME,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    applyTheme(state, action: PayloadAction<DefaultTheme>) {
      state.theme = action.payload;
    },
  },
});
export const {applyTheme} = themeSlice.actions;
export default themeSlice.reducer;
