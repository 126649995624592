import {CustomSvg} from '../lib/react';

interface LocationProps {
  size: number;
}

export const Location: CustomSvg<LocationProps> = ({size, color, ...rest}) => (
  <svg width={size} height={size} {...rest} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 7.00065C1.75 4.13692 4.02323 2.08398 7 2.08398C9.97677 2.08398 12.25 4.13692 12.25 7.00065C12.25 9.37296 11.1989 11.1024 9.98437 12.2522C9.37427 12.8298 8.72809 13.2553 8.16692 13.5334C7.58095 13.8239 7.16261 13.9172 7 13.9172C6.83739 13.9172 6.41905 13.8239 5.83308 13.5334C5.27191 13.2553 4.62573 12.8298 4.01563 12.2522C2.80107 11.1024 1.75 9.37296 1.75 7.00065ZM7 0.583984C3.31011 0.583984 0.25 3.19771 0.25 7.00065C0.25 9.86289 1.53227 11.9667 2.98437 13.3415C3.7076 14.0262 4.47809 14.536 5.16692 14.8774C5.83095 15.2065 6.49594 15.4172 7 15.4172C7.50406 15.4172 8.16905 15.2065 8.83308 14.8774C9.52191 14.536 10.2924 14.0262 11.0156 13.3415C12.4677 11.9667 13.75 9.86289 13.75 7.00065C13.75 3.19771 10.6899 0.583984 7 0.583984ZM5.75 7.33398C5.75 6.64363 6.30964 6.08398 7 6.08398C7.69036 6.08398 8.25 6.64363 8.25 7.33398C8.25 8.02434 7.69036 8.58398 7 8.58398C6.30964 8.58398 5.75 8.02434 5.75 7.33398ZM7 4.58398C5.48122 4.58398 4.25 5.8152 4.25 7.33398C4.25 8.85277 5.48122 10.084 7 10.084C8.51878 10.084 9.75 8.85277 9.75 7.33398C9.75 5.8152 8.51878 4.58398 7 4.58398Z"
      fill="currentColor"
    />
  </svg>
);
