import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {SearchCTDataProps} from '../models';

export enum ExtraEnum {
  RESET_FILTERS = 'reset-filters',
}

export interface ParamsModal {
  type?: string;
  title?: string;
  body?: string;
  extra?: ExtraEnum;
  data?: SearchCTDataProps;
}
export interface ModalState {
  show: boolean;
  modalType?: string;
  param?: ParamsModal;
}

const initialState: ModalState = {
  show: false,
  modalType: '',
  param: {},
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<{modalType: string; param?: ParamsModal}>) {
      state.show = true;
      state.modalType = action.payload.modalType;
      state.param = action.payload.param;
    },
    hideModal(state) {
      state.show = false;
    },
  },
});
export const {showModal, hideModal} = modalSlice.actions;
export default modalSlice.reducer;
