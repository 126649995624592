import {
  BLUE_4_SECONDARY_DARK,
  Button,
  H2,
  P1,
  P3,
  Spacing,
  Spinner,
  Text,
  WHITE,
  getIcon,
} from '@devipsendigital/react-ui-kit';
import moment from 'moment';
import {FC, createRef, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {useTheme} from 'styled-components';

import {useSearchCTResultMutation} from '../../api/apiGateway';
import {isObjectEmpty} from '../../api/api_parsers';
import {
  BLUE_BUTTON_LIGHT,
  BLUE_LIGHT_HALEX,
  BLUE_LIGHT_HALEX_LIGHT_BLUE,
  BLUE_LIGHT_HALEX_LIGHT_OPACITY,
  BLUE_LIGHT_HALEX_OPACITY,
  DARK_GREY_1,
  DARK_GREY_3,
  DARK_GREY_3_DARK_OPACITY,
  DARK_GREY_3_OPACITY,
  DARK_GREY_6,
  GREEN_BRIGHT,
  PINK_BRIGHT_LIGHT_OPACITY,
  PINK_LIGHT,
  YELLOW_BRIGHT,
} from '../../colors';
import exportAsImage from '../../lib/downloadImage';
import {SearchCTDataProps, Status, ThemeProps} from '../../lib/models';
import {setMoreData} from '../../lib/stores/clinical';
import {showModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';
import {Information} from '../../svg/information';
import {DropdownButton} from '../core/DropdownButton';
import {WrapperSpinner} from '../stylesGeneric';

import {Stage} from './Stage';

export const statuses = [
  {status_ref: 'Active, not recruiting', color: {light: DARK_GREY_3, dark: DARK_GREY_3}},
  {status_ref: 'Not yet recruiting', color: {light: DARK_GREY_6, dark: DARK_GREY_6}},
  {
    status_ref: 'Unknown status',
    color: {light: DARK_GREY_3_DARK_OPACITY, dark: DARK_GREY_3_OPACITY},
  },
  {
    status_ref: 'Enrolling by invitation',
    color: {light: BLUE_LIGHT_HALEX_LIGHT_OPACITY, dark: BLUE_LIGHT_HALEX_LIGHT_BLUE},
  },
  {status_ref: 'Recruiting', color: {light: BLUE_LIGHT_HALEX, dark: BLUE_LIGHT_HALEX_OPACITY}},
  {status_ref: 'Terminated', color: {light: BLUE_BUTTON_LIGHT, dark: BLUE_BUTTON_LIGHT}},
  {status_ref: 'Completed', color: {light: GREEN_BRIGHT, dark: GREEN_BRIGHT}},
  {status_ref: 'Suspended', color: {light: YELLOW_BRIGHT, dark: YELLOW_BRIGHT}},
  {status_ref: 'Withdrawn', color: {light: PINK_BRIGHT_LIGHT_OPACITY, dark: PINK_LIGHT}},
];

export const ClinicalResults: FC = () => {
  const {
    data: dataStore,
    nbDisplayed,
    nbRemove,
    modalInfoShown,
  } = useSelector((state: RootState) => state.clinical);
  const filterSaved = useSelector((state: RootState) => state.clinical.filters);
  const {skip} = useSelector((state: RootState) => state.clinical.data);
  const data = dataStore.results;
  const total = dataStore.total;

  const dispatch = useDispatch();

  const [hasFilterValueSelected, setHasFilterValueSelected] = useState(false);

  const [showButton, setShowButton] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [displayLegend, setDisplayLegend] = useState(false);

  const hasFiltersValue = useCallback(() => {
    const hasValue = Object.values(filterSaved).find((val: string[]) => {
      return val.length > 0;
    });
    return hasValue === undefined ? false : true;
  }, [filterSaved]);

  useEffect(() => {
    setHasFilterValueSelected(hasFiltersValue());
  }, [filterSaved, hasFiltersValue]);

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.screenY > 300 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener('scroll', handleScrollButtonVisibility);

    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    };
  }, []);

  const currentRef = createRef<HTMLDivElement>();

  const handleDownloadClick = useCallback(() => {
    const date = moment(moment.now()).format('DD/MM/YYYY-hh_mm_ss');
    exportAsImage(currentRef.current, `HALEX_clinical_trials_searches_${date}`);
    setDisplayLegend(false);
  }, [currentRef]);

  const prepCanvaForDownload = useCallback(() => {
    setDisplayLegend(!displayLegend);
  }, [displayLegend]);

  useEffect(() => {
    if (displayLegend) {
      handleDownloadClick();
      setDisplayLegend(false);
    }
  }, [displayLegend, handleDownloadClick]);

  const [postResultForCTResults, {isLoading}] = useSearchCTResultMutation();

  const displayMoreResult = useCallback(async () => {
    const selectAllToRemove = filterSaved.overall_status.findIndex(val => val === '- Select All -');
    const filters = {
      ...filterSaved,
      overall_status: selectAllToRemove !== -1 ? [] : filterSaved.overall_status,
    };
    postResultForCTResults({limit: 10, body: filters, skip: skip})
      .unwrap()
      .then((payload: SearchCTDataProps) => {
        const {results} = payload;
        const nbToDisplayed =
          nbDisplayed +
          (results.phase1 !== undefined ? results.phase1.length : 0) +
          (results.phase2 !== undefined ? results.phase2.length : 0) +
          (results.phase3 !== undefined ? results.phase3.length : 0) +
          (results.phase4 !== undefined ? results.phase4.length : 0);
        if (nbToDisplayed > 90 && !modalInfoShown) {
          dispatch(
            showModal({
              modalType: 'information',
              param: {
                body: 'Attention you will exceed the limit of downloadable results. If you continue you will not be able to upload your changes.',
                data: payload,
              },
            })
          );
        } else {
          dispatch(setMoreData(payload));
        }
      });
  }, [postResultForCTResults, filterSaved, skip, nbDisplayed, modalInfoShown, dispatch]);
  // HAL-428 - Remove function because can't handle with the back
  // const displayLessResult = useCallback(async () => {
  //   postResultForCTResults({limit: limit, skip: limit - 30, body: filterSaved})
  //     .unwrap()
  //     .then((payload: SearchCTDataProps) => {
  //       dispatch(setData(payload));
  //     });
  // }, [postResultForCTResults, limit, filterSaved, dispatch]);

  const checkIsLessThan500CTResults = () => {
    return total === 500;
  };

  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const theme = useTheme() as ThemeProps;

  const getNbPhaseNotNull = () => {
    const key = Object.keys(data);
    const getPhasesNumber = key.map(val => {
      const tab = data[val as keyof typeof data];
      return tab !== undefined ? tab.length : 0;
    });
    return getPhasesNumber.filter(val => val > 0).length;
  };

  return (
    <>
      {isObjectEmpty(data) !== 0 ? (
        <>
          <WrapperPhaseContentHeader>
            <TitleTable>
              <WrapperNbSearch>{`${
                nbDisplayed < total ? nbDisplayed : total
              } / ${total}`}</WrapperNbSearch>
              <Spacing width={10} />
              <H2 color={theme.name === 'light' ? DARK_GREY_1 : WHITE}>Search results</H2>
              <Spacing width={10} />
              <InformationSvgContainer
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
              >
                <Information size={20} />
              </InformationSvgContainer>
              {isShown && (
                <>
                  <StatusLegendContainer>
                    {statuses.map((status: Status, index) => (
                      <StatusContent key={index}>
                        <ColorBlock
                          backgroundColor={
                            theme.name === 'light' ? status.color.light : status.color.dark
                          }
                        ></ColorBlock>
                        <Spacing width={5} />
                        <TextBlock>{status.status_ref}</TextBlock>
                      </StatusContent>
                    ))}
                  </StatusLegendContainer>
                </>
              )}
            </TitleTable>
            <DownloadButton
              button={
                <ButtonDownload
                  icon="download"
                  onClick={prepCanvaForDownload}
                  size="medium"
                  typeButton="secondary"
                  borderDisabled
                  disabled={nbDisplayed > 90}
                >
                  Download
                </ButtonDownload>
              }
              options={[]}
            />
          </WrapperPhaseContentHeader>
          {nbRemove > 0 && (
            <>
              <H2 color={theme.name === 'light' ? DARK_GREY_1 : WHITE}>
                Results deleted : {nbRemove}
              </H2>
              <Spacing height={10} />
            </>
          )}
          <WrapperNote>
            <Note color={theme.name === 'light' ? DARK_GREY_1 : WHITE}>
              Note: The dates below are the Estimated/Actual Primary Completion Date
            </Note>
            <Note color={theme.name === 'light' ? DARK_GREY_1 : WHITE}>
              Note: Downloading is limited to 90 clinical trials displayed
            </Note>
          </WrapperNote>

          {checkIsLessThan500CTResults() && (
            <NoteWarning>You have more than 500 results, please refine your search</NoteWarning>
          )}
          <Spacing height={20} />
          <WrapperDownloadContainer ref={currentRef}>
            <WrapperPhasesContainer>
              {isObjectEmpty(data) !== 0 &&
                Object.keys(data).map((phase, phaseIndex) => {
                  const phaseContent = data[phase as keyof typeof data];
                  return (
                    <>
                      {phaseContent !== undefined && phaseContent.length > 0 ? (
                        <WrapperPhaseContainer
                          key={`${phase}_${new Date().getTime()}${phaseIndex}`}
                        >
                          <WrapperPhaseHeader key={`${phaseIndex}-${new Date().getTime()}`}>
                            <Text
                              key={`${phase}-${new Date().getTime()}`}
                              color="white"
                              size="xsmall"
                              weight="bold"
                            >
                              {`${
                                phase[0].toUpperCase() +
                                phase.slice(1, phase.length - 1).toUpperCase()
                              } ${phase.slice(-1)}`}
                            </Text>
                          </WrapperPhaseHeader>
                          <WrapperPhaseContent nbPhase={getNbPhaseNotNull()}>
                            {phaseContent.map((content, index) => {
                              const status = content.overall_status;
                              return (
                                <Stage
                                  key={`${index}-${content.nct_identifier}-${new Date().getTime()}`}
                                  identifier={content.nct_identifier}
                                  interventions={content.interventions}
                                  companyName={content.lead_sponsor}
                                  conditions={content.conditions}
                                  anticipatedDate={content.primary_completion_date_anticipated}
                                  actualDate={content.primary_completion_date_actual}
                                  backgroundColor={statuses.map(
                                    basedStatus =>
                                      basedStatus.status_ref === status &&
                                      (theme.name === 'light'
                                        ? basedStatus.color.light
                                        : basedStatus.color.dark)
                                  )}
                                  phaseLength={phaseContent.length}
                                />
                              );
                            })}
                          </WrapperPhaseContent>
                        </WrapperPhaseContainer>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
            </WrapperPhasesContainer>
            {displayLegend && (
              <>
                <Spacing height={20} />
                <LegendContainerForDownload>
                  <StatusLegendContainerDownload>
                    {statuses.map((status: Status, index) => (
                      <StatusContent key={index}>
                        <ColorBlock
                          backgroundColor={
                            theme.name === 'light' ? status.color.light : status.color.dark
                          }
                        ></ColorBlock>
                        <Spacing width={5} />
                        <TextBlock>{status.status_ref}</TextBlock>
                      </StatusContent>
                    ))}
                  </StatusLegendContainerDownload>
                </LegendContainerForDownload>
              </>
            )}
          </WrapperDownloadContainer>
          <Spacing height={10} />
          <ButtonContainer>
            <Button
              onClick={displayMoreResult}
              typeButton={'primary'}
              size={'medium'}
              width={270}
              disabled={nbDisplayed >= total}
            >
              {isLoading ? (
                <WrapperSpinner>
                  <Spinner spinnerSize={30} spinnerThickness={3} color={WHITE} />
                </WrapperSpinner>
              ) : (
                'Display more results'
              )}
            </Button>
            <Spacing width={10} />
            {/* HAL-428 remove this functionnality
            <Button onClick={displayLessResult} typeButton={'primary'} size={'medium'} width={270}>
              {isLoading ? (
                <WrapperSpinner>
                  <Spinner spinnerSize={30} spinnerThickness={3} color={WHITE} />
                </WrapperSpinner>
              ) : (
                'Display less results'
              )}
            </Button> */}
            <Spacing width={10} />
          </ButtonContainer>
          <ShowButtonContainer>
            {showButton && (
              <ArrowUp onClick={handleScrollToTop}>{getIcon('expandableArrowUp', 100)}</ArrowUp>
            )}
          </ShowButtonContainer>
        </>
      ) : (
        <>
          {isObjectEmpty(data) === 0 && Object.keys(data).length <= 0 && hasFilterValueSelected && (
            <Note color={theme.name === 'light' ? DARK_GREY_1 : WHITE}>
              No clinical trials match the specified search terms
            </Note>
          )}
        </>
      )}
    </>
  );
};

const WrapperPhaseHeader = styled.div`
  display: flex;
  align-items: center;
  background: ${BLUE_LIGHT_HALEX};
  border: 1px solid ${BLUE_LIGHT_HALEX};
  border-radius: 8px;
  padding: 0 0 0 10px;
  height: 30px;
  margin-bottom: 0.5em;
`;

const Note = styled(P3)`
  margin-bottom: 20px;
`;

export const NoteWarning = styled(P1)`
  color: red;
  font-weight: bold;
`;

const WrapperPhasesContainer = styled.div`
  display: flex;
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
  padding: 30px;
`;

const WrapperPhaseContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media print {
    border: none;
  }
`;

const WrapperNote = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperPhaseContent = styled.div<{nbPhase: number}>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: ${({nbPhase}) =>
    nbPhase === 1 ? 'repeat(4, 1fr)' : nbPhase === 2 ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'};
  grid-gap: 0.5em;
  @media print {
    border: none;
  }
`;

const WrapperPhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  flex: 1;
`;

const InformationSvgContainer = styled.div`
  cursor: pointer;
`;

const StatusLegendContainer = styled.div`
  position: absolute;
  left: 25rem;
  background: ${({theme}) => theme.inputSelect.colors.background};
  border: 1px solid ${DARK_GREY_3};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 5px 10px 0;
  z-index: 1;
`;

const StatusLegendContainerDownload = styled.div`
  background: ${({theme}) => theme.inputSelect.colors.background};
  display: flex;
  border: 1px solid ${DARK_GREY_3};
  border-radius: 8px;
  width: 150px;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 5px 10px 0;
  z-index: 1;
`;

const StatusContent = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 10px;
`;

const ColorBlock = styled.div<{backgroundColor: string}>`
  border: 5px solid ${({backgroundColor}) => backgroundColor};
  background: ${({backgroundColor}) => backgroundColor};
  height: 10px;
`;

const TextBlock = styled.div`
  color: ${({theme}) => theme.colors.textColor};
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
`;

const TitleTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const LegendContainerForDownload = styled.div`
  width: 100%;
`;

const WrapperNbSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  background: ${BLUE_LIGHT_HALEX};
  border-radius: 8px;
  @media print {
    display: none;
  }
`;

const ButtonDownload = styled(Button)`
  border: none;
  @media print {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShowButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`;

const DownloadButton = styled(DropdownButton)`
  color: ${BLUE_4_SECONDARY_DARK};
  @media print {
    display: none;
  }
`;

const WrapperDownloadContainer = styled.div`
  border: 1px solid #c6c9d2;
  border-radius: 8px;
  background: ${({theme}) => theme.inputSelect.colors.background};
`;

const ArrowUp = styled.div`
  background: ${({theme}) => theme.switch.toggle.colors.normal};
  border-radius: 27px;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.switch.toggle.colors.normal};
  }
`;
