import {isRejectedWithValue} from '@reduxjs/toolkit';
import type {MiddlewareAPI, Middleware} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

export const rtkQueryErrorLogger: Middleware = (_api: MiddlewareAPI) => next => action => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (isRejectedWithValue(action)) {
    const {status, originalStatus, data} = action.payload;
    if (
      data.type !== 'CompanyNotFound' &&
      data.type !== 'TooManyResults' &&
      data.type !== 'ToManyClauses'
    ) {
      const message =
        status === 'FETCH_ERROR' || originalStatus === 500
          ? 'The server is temporarily unavailable, please try again later'
          : action.payload.data.message;
      toast.error(`${message}`);
    }
  }
  return next(action);
};
