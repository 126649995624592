import {DropdownGeneric} from '@devipsendigital/react-ui-kit';
import {useState} from 'react';

import {useGetApiAutocompleteQuery} from '../../api/apiGateway';

export enum FeatureName {
  ASSET = 'asset',
  CLINICALS = 'ct',
}

export enum FieldAutocomplete {
  INDICATION = 'indication',
  ACTIVE_COMPAGNIES_PROCESSED = 'active_companies',
  ACTIVE_COMPAGNIES_GEOGRAPHY = 'active_companies_geography',
  ALL_DRUGS_PROCESSED = 'all_drugs',
  PRODUCT_GEOGRAPHY = 'product_geography',
  TARGET_GD = 'target_based_actions',
  DRUG_CATEGORY = 'drug_category',
  REGULATORY = 'regulatory_designations',
  CONDITIONS = 'all_conditions',
  HIGHEST_STATUS_REGION = 'highest_status_region',
}

interface DropdownAutocompleteProps {
  selected: string[];
  onChange: (selected: string[]) => void;
  field: FieldAutocomplete;
  placeholder: string;
  feature: FeatureName;
  defaultValue?: string[];
  addSelectAll?: boolean;
}

export const DropdownAutocomplete = ({
  selected,
  onChange,
  field,
  placeholder,
  feature,
  defaultValue,
  addSelectAll,
}: DropdownAutocompleteProps) => {
  const [inputValue, setInputValue] = useState('');

  const {data} = useGetApiAutocompleteQuery({
    q: inputValue,
    field_name: field,
    limit: 10,
    ref: feature,
  });

  return (
    <>
      <DropdownGeneric
        selected={selected}
        onChange={onChange}
        placeholder={placeholder}
        isOnlySelect={false}
        data={
          data !== undefined
            ? data.length > 0
              ? data
              : defaultValue !== undefined
              ? defaultValue
              : []
            : []
        }
        inputValue={inputValue}
        setInputValue={setInputValue}
        addSelectAll={addSelectAll}
      />
    </>
  );
};
