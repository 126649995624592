import {DropdownGeneric} from '@devipsendigital/react-ui-kit';

export enum FieldNameAsset {
  DEVELOPMENT_STAGE = 'developmentStage',
  HIGHEST_STATUS = 'highestStatus',
  HIGHEST_STATUS_REGION = 'highestStatusRegion',
  THERAPY_AREA = 'therapies',
  PRODUCT_GEOGRAPHY = 'productGeography',
  ACTIVE_COMPAGNIES_GEOGRAPHY = 'companiesGeography',
  REGULATORY_DESIGNATIONS = 'regulatoryDesignations',
}
export enum FieldNameClinicals {
  STATUS = 'status',
  PHASES = 'phases',
  GENDER = 'gender',
  AGE_GROUP = 'age',
  STUDY = 'study',
  FUNDER = 'funder',
  HEALTHY = 'healthy',
}

interface DropdownSelectProps {
  selected: string[];
  doNotShowSelected?: boolean;
  onChange: (selected: string[]) => void;
  placeholder: string;
  isSingleValue?: boolean;
  data: string[];
  addSelectAll?: boolean;
}

export const DropdownSelect = ({
  selected,
  doNotShowSelected,
  onChange,
  placeholder,
  isSingleValue,
  data,
  addSelectAll,
}: DropdownSelectProps) => {
  return (
    <>
      <DropdownGeneric
        selected={selected}
        doNotShowSelected={doNotShowSelected}
        onChange={onChange}
        placeholder={placeholder}
        isOnlySelect={true}
        data={data}
        isSingleValue={isSingleValue}
        addSelectAll={addSelectAll}
      />
    </>
  );
};
