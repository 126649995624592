import {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import {DARK_GREY_3} from '../../colors';
import {ExtraEnum, ParamsModal, hideModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';
import {ExitFromPhase} from '../../svg/exitFromPhase';

import {ModalActions, ModalContent, ModalTitle, WrapperExit} from './ModalFragments';
import {SaveSearchesForm} from './SaveSearchesForm';

export enum savedSearches {
  SAVED_CT = 'ct',
  SAVED_ASSET = 'asset',
}

interface SaveSeachedModalProps {
  param: ParamsModal;
}

export const SaveSearchesModal: FC<SaveSeachedModalProps> = ({param}) => {
  const [, setSavingState] = useState<'saving' | 'success' | Error>('saving');
  const dispatch = useDispatch();

  const {show} = useSelector((state: RootState) => state.modal);

  useEffect(() => setSavingState('saving'), []);

  const handleSavingFailure = useCallback((err: Error) => setSavingState(err), []);
  const handleSubmit = useCallback(() => {
    toast.success(`Your search has been added to your saves`);
    dispatch(hideModal());
  }, [dispatch]);

  const handleHideModal = useCallback(() => {
    if (show === true) {
      dispatch(hideModal());
    }
  }, [dispatch, show]);

  return (
    <>
      <ModalActions>
        <ModalTitle>
          {param?.extra === ExtraEnum.RESET_FILTERS
            ? 'Are you sure you want to reset your search?'
            : 'Save your search'}
        </ModalTitle>
        <WrapperExit>
          <ExitFromPhase color={DARK_GREY_3} size={25} onClick={handleHideModal} />
        </WrapperExit>
      </ModalActions>
      <ModalContent>
        <SaveSearchesForm onSuccess={handleSubmit} onFailure={handleSavingFailure} />
      </ModalContent>
    </>
  );
};
