import React, {Fragment, useCallback, useRef, useState} from 'react';
import {BLACK, WHITE} from '@devipsendigital/react-ui-kit';
import styled from 'styled-components';

import {GRAY_4} from '../../colors';
import {useClickOutside} from '../../lib/hooks/click_out_hook';
import {CustomDiv} from '../../lib/react';

interface DropdownOption {
  title: string;
  onClick?: () => void;
  icon?: JSX.Element;
}

interface DropdownButtonProps {
  button: JSX.Element;
  options: DropdownOption[];
}

export const DropdownButton: CustomDiv<DropdownButtonProps> = props => {
  const {button, options, ...rest} = props;

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const [shown, setShown] = useState(false);
  const handleButtonClick = useCallback<React.MouseEventHandler>(() => setShown(s => !s), []);

  useClickOutside([buttonRef, dropdownRef], () => setShown(false));

  return (
    <Wrapper {...rest}>
      <div ref={buttonRef} onClick={handleButtonClick}>
        {button}
      </div>
      <Dropdown ref={dropdownRef} shown={shown}>
        {options.map(({title, icon, onClick}) => (
          <DropdownItem key={title} onClick={onClick}>
            {icon !== undefined ? <DropdownItemIcon>{icon}</DropdownItemIcon> : <Fragment />}
            <DropdownItemText>{title}</DropdownItemText>
          </DropdownItem>
        ))}
      </Dropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;
const Dropdown = styled.div<{shown: boolean}>`
  position: absolute;
  top: 50px;
  right: 0;
  transition: opacity 150ms ease;
  pointer-events: ${({shown}) => (shown ? 'all' : 'none')};
  opacity: ${({shown}) => (shown ? 1 : 0)};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 4px 0;
  overflow: hidden;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${GRAY_4};
  }
`;

const DropdownItemText = styled.div`
  color: ${p => (p.theme.name === 'light' ? BLACK : WHITE)};
`;
const DropdownItemIcon = styled.div`
  margin-right: 16px;
`;
