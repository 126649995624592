import {
  ArrowDown,
  ArrowUp,
  BLACK,
  BLUE_MIDDLE_BLUE_HALEX,
  P3,
  Table,
  WHITE,
} from '@devipsendigital/react-ui-kit';
import {ColumnDef} from '@tanstack/table-core';
import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {BLUE_BUTTON_LIGHT, BLUE_LIGHT_HALEX, DARK_GREY_1} from '../../colors';
import {IDataSearchResult, Indications, ThemeProps} from '../../lib/models';
import {showModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';
import {
  CellWrapperGeneric,
  CellWrapperGenericPin,
  CellWrapperPosStartEnd,
} from '../../screens/CellWrapper';
import {NoteWarning} from '../clinical/ClinicalResults';
import {IndeterminateCheckbox} from '../core/Intermediate';

interface TableSearchProps {
  statusNbRows: string[];
  rowSelection: {};
  setRowSelection: Dispatch<SetStateAction<{}>>;
  searchId?: string;
}

export const TableSearch: React.FC<TableSearchProps> = ({
  statusNbRows,
  rowSelection,
  setRowSelection,
}) => {
  const {data, filters, total, searchId} = useSelector((state: RootState) => state.search);
  const theme = useSelector((state: RootState) => state.theme.theme as ThemeProps);

  const [expanded, setExpanded] = React.useState<any>({});
  const [hovered, setHovered] = useState<string | null>(null);
  const dispatch = useDispatch();

  const [hasFilterValueSelected, setHasFilterValueSelected] = useState(false);

  const hasFiltersValue = useCallback(() => {
    const hasValue = Object.values(filters).find((val: string[]) => {
      return val.length > 0;
    });
    return hasValue === undefined ? false : true;
  }, [filters]);

  useEffect(() => {
    setHasFilterValueSelected(hasFiltersValue());
  }, [filters, hasFiltersValue]);

  const doNotHoveredKeys = [
    'expander',
    'selection',
    'drug',
    'company',
    'companies',
    'geography',
    'targetcortellis',
    'indications',
    'category',
    'status',
    'region',
    'product',
    'stage',
    'aliases',
    'designation',
    'therapy',
    'regulatory',
  ];

  const [, setRowFlatSelection] = React.useState<any>([]);

  const showCompanyModal = useCallback(
    (name: string) => {
      dispatch(
        showModal({
          modalType: 'display-company',
          param: {
            title: name,
          },
        })
      );
    },
    [dispatch]
  );

  const displayColorCell = useCallback(
    (src: string) => {
      if (theme.name === 'light') {
        if (src === 'gd') {
          return BLUE_LIGHT_HALEX;
        } else {
          return BLUE_BUTTON_LIGHT;
        }
      } else {
        if (src === 'gd') {
          return BLUE_BUTTON_LIGHT;
        } else {
          return BLUE_LIGHT_HALEX;
        }
      }
    },
    [theme.name]
  );

  const columns = useMemo<ColumnDef<IDataSearchResult>[]>(
    () => [
      {
        id: 'expander',
        cell: ({row}) =>
          row.getCanExpand() && row.depth <= 0 ? (
            <ButtonContainer>
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: {cursor: 'pointer', background: 'transparent', border: 'none'},
                }}
              >
                {row.getIsExpanded() ? (
                  <ArrowContainerUp>
                    <ArrowUp size={20} color={BLUE_MIDDLE_BLUE_HALEX} />
                  </ArrowContainerUp>
                ) : (
                  <ArrowContainer>
                    <ArrowDown size={20} color={BLUE_MIDDLE_BLUE_HALEX} />
                  </ArrowContainer>
                )}
              </button>
            </ButtonContainer>
          ) : null,
        size: 40,
      },
      {
        id: 'selection',
        size: 50,
        header: ({table}) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        enableSorting: false,
        cell: ({row}) => (
          <CellWrapperPosStartEnd>
            {row.depth > 0 ? null : (
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            )}
          </CellWrapperPosStartEnd>
        ),
      },
      {
        header: 'Drug name',
        accessorKey: 'drug',
        enableSorting: false,
        cell: props => (
          <CellWrapperGeneric
            value={props.getValue<string[]>()}
            color={theme.name === 'light' ? BLACK : WHITE}
          ></CellWrapperGeneric>
        ),
      },
      {
        header: 'Active Company',
        accessorKey: 'companies',
        enableSorting: false,
        cell: props => (
          <CellWrapperGeneric
            onClick={() => showCompanyModal(props.getValue<string>())}
            value={props.getValue<string[]>()}
            color={theme.name === 'light' ? BLACK : WHITE}
            fontWeight="700"
          ></CellWrapperGeneric>
        ),
      },
      {
        header: 'Active Company Location',
        accessorKey: 'geography',
        enableSorting: false,
        size: 210,
        cell: props => (
          <CellWrapperGeneric
            value={props.getValue<string[]>()}
            color={theme.name === 'light' ? BLACK : WHITE}
            fontWeight="700"
          ></CellWrapperGeneric>
        ),
      },
      {
        header: 'Therapeutic Area',
        accessorKey: 'therapy',
        enableSorting: false,
        cell: props => (
          <CellWrapperGenericPin
            value={props.getValue<string[]>()}
            color={theme.name === 'light' ? BLACK : WHITE}
          ></CellWrapperGenericPin>
        ),
      },
      {
        header: 'Indication',
        accessorKey: 'indications',
        enableSorting: false,
        cell: props => {
          const {indication, src} = props.getValue<Indications>();

          return (
            <CellWrapperGeneric
              value={[indication]}
              color={
                theme.name === 'light'
                  ? src === 'gd'
                    ? BLUE_LIGHT_HALEX
                    : BLUE_BUTTON_LIGHT
                  : src === 'gd'
                  ? BLUE_BUTTON_LIGHT
                  : BLUE_LIGHT_HALEX
              }
              fontWeight="700"
            ></CellWrapperGeneric>
          );
        },
      },
      {
        header: 'Development Stage',
        accessorKey: 'stage',
        enableSorting: false,
        size: 170,
        cell: props => {
          const {stage, src} = props.getValue<{stage: string; src: string}>();
          return (
            <CellWrapperGeneric
              value={[stage]}
              color={displayColorCell(src)}
              fontWeight="700"
            ></CellWrapperGeneric>
          );
        },
      },
      {
        header: 'Product Geography',
        accessorKey: 'product',
        enableSorting: false,
        size: 170,
        cell: props => {
          const {productGeography, src} = props.getValue<{productGeography: string; src: string}>();

          return (
            <CellWrapperGeneric
              value={[productGeography]}
              color={displayColorCell(src)}
              fontWeight="700"
            ></CellWrapperGeneric>
          );
        },
      },

      {
        header: 'MOA',
        accessorKey: 'targetcortellis',
        enableSorting: false,
        size: 150,
        cell: props => (
          <CellWrapperGenericPin
            value={props.getValue<string[]>()}
            color={theme.name === 'light' ? BLACK : WHITE}
          ></CellWrapperGenericPin>
        ),
      },
      {
        header: 'Highest Status',
        accessorKey: 'status',
        enableSorting: false,
        cell: props => {
          const {status, src} = props.getValue<{status: string; src: string}>();
          return (
            <CellWrapperGeneric
              value={[status]}
              color={displayColorCell(src)}
              fontWeight="700"
            ></CellWrapperGeneric>
          );
        },
      },
      {
        header: 'Highest Status Region',
        accessorKey: 'region',
        enableSorting: false,
        size: 200,
        cell: props => {
          const {region, src} = props.getValue<{region: string; src: string}>();
          return (
            <CellWrapperGeneric
              value={[region]}
              color={displayColorCell(src)}
              fontWeight="700"
            ></CellWrapperGeneric>
          );
        },
      },
      {
        header: 'Regulatory Designation',
        accessorKey: 'regulatory',
        enableSorting: false,
        size: 170,
        cell: props => {
          const {regulatory, src} = props.getValue<{regulatory: string; src: string}>();

          return (
            <CellWrapperGeneric
              value={[regulatory]}
              color={displayColorCell(src)}
              fontWeight="700"
            ></CellWrapperGeneric>
          );
        },
      },
      {
        header: 'Drug category',
        accessorKey: 'category',
        enableSorting: false,
        size: 150,
        cell: props => (
          <CellWrapperGenericPin
            value={props.getValue<string[]>()}
            color={theme.name === 'light' ? BLACK : WHITE}
          ></CellWrapperGenericPin>
        ),
      },
      {
        header: 'Drug Aliases',
        accessorKey: 'aliases',
        enableSorting: false,
        size: 150,
        cell: props => (
          <CellWrapperGenericPin
            value={props.getValue<string[]>()}
            color={theme.name === 'light' ? BLACK : WHITE}
          ></CellWrapperGenericPin>
        ),
      },
      {
        header: 'Originator Company',
        accessorKey: 'company',
        enableSorting: false,
        size: 200,
        cell: props => (
          <CellWrapperGeneric
            value={props.getValue<string[]>()}
            color={theme.name === 'light' ? BLACK : WHITE}
          ></CellWrapperGeneric>
        ),
      },
    ],
    [displayColorCell, showCompanyModal, theme.name]
  );

  const checkIsLessThan500CTResults = () => {
    return total >= 500 || searchId === 'failed';
  };

  const tooManyResquest = () => {
    return searchId === 'ToManyClauses';
  };

  return (
    <>
      {checkIsLessThan500CTResults() && (
        <NoteWarning>
          You have more than 500 results or 5k indications, please refine your search
        </NoteWarning>
      )}
      {data.length > 0 ? (
        <Table
          key={statusNbRows.length === 0 ? 40 : parseInt(statusNbRows[0])}
          data={data}
          setHovered={setHovered}
          setExpanded={setExpanded}
          expanded={expanded}
          hovered={hovered}
          columns={columns}
          nbRows={statusNbRows.length === 0 ? 40 : parseInt(statusNbRows[0])}
          setRowSelection={setRowSelection}
          rowSelection={rowSelection}
          setRowFlatSelection={setRowFlatSelection}
          columnResize={true}
          doNotHoveredKeys={doNotHoveredKeys}
        ></Table>
      ) : (
        <>
          {hasFilterValueSelected &&
            data.length === 0 &&
            searchId !== '' &&
            !checkIsLessThan500CTResults() &&
            !tooManyResquest() && (
              <Note color={theme.name === 'light' ? DARK_GREY_1 : WHITE}>
                No assets match the specified search terms
              </Note>
            )}

          {tooManyResquest() && hasFilterValueSelected && (
            <NoteWarning>Your search has too many elements, please reduce your filters</NoteWarning>
          )}
        </>
      )}
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ArrowContainer = styled.div`
  rotate: -90deg;
`;

const ArrowContainerUp = styled.div`
  rotate: -180deg;
`;

const Note = styled(P3)`
  margin-bottom: 20px;
`;
