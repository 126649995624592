import {CustomSvg} from '../lib/react';

interface InformationProps {
  size: number;
}

export const Information: CustomSvg<InformationProps> = ({size, color, ...rest}) => (
  <svg width={size} height={size} fill="none" {...rest} viewBox="0 0 25 25">
    <path d="M12.4974 18.3385L12.4974 11.6719H10.4141" stroke="#C6C9D2" strokeWidth="2" />
    <circle cx="12.5" cy="12.5" r="11.5" stroke="#C6C9D2" strokeWidth="2" />
    <circle cx="12.4987" cy="8.33854" r="1.66667" fill="#C6C9D2" />
  </svg>
);
