export const BLUE_HALEX_MAIN = '#4188DB';
export const BLUE_HALEX_SECONDARY = '#6EB6C7';
export const BLUE_BUTTON_LIGHT = '#377FD3';
export const BLUE_BUTTON_LIGHT_HOVER = '#4188DB';
export const BLUE_BUTTON_LIGHT_HOVER_LIGHT = 'rgba(65, 136, 219, 0.1)';
export const BLUE_LIGHT_HALEX = '#84B8F5';
export const BLUE_LIGHT_HALEX_OPACITY = '#6BAFFF';
export const BLUE_LIGHT_HALEX_LIGHT = 'rgba(132, 184, 245, 0.1)';
export const BLUE_LIGHT_HALEX_LIGHT_OPACITY = 'rgba(132, 184, 245, 0.5)';
export const BLUE_LIGHT_HALEX_DARK = '#73BCFF';
export const BLUE_LIGHT_HALEX_LIGHT_BLUE = '#9BC9FF';
export const GRAY_4 = '#E5E5E5';
export const GRAY_4_OPACITY = 'rgba(229, 229, 229, 0.4)';
export const DARK_GREY_1 = '#2B2E39';
export const DARK_GREY_2 = '#7A7D90';
export const DARK_GREY_3 = '#C6C9D2';
export const DARK_GREY_3_OPACITY = '#DCDDE1';
export const DARK_GREY_3_DARK_OPACITY = 'rgba(198,201,210,0.4)';
export const DARK_GREY_4 = '#12131E';
export const DARK_GREY_5 = '#27273b';
export const DARK_GREY_6 = '#7A7D88';
export const DARK_GREY_7 = '#22272F';
export const GREEN_LIGHT = '#E0F0DD';
export const GREEN_BRIGHT = '#ACDEA4';
export const GREEN_BRIGHT_OPACITY = 'rgba(193, 225, 188, 0.5)';
export const PINK_LIGHT = '#EFC8D5';
export const PINK_BRIGHT = '#DF8BA8';
export const PINK_BRIGHT_LIGHT = '#C84874';
export const PINK_BRIGHT_LIGHT_OPACITY = 'rgba(200,72,116,0.3)';
export const YELLOW_BRIGHT = '#FAC26D';
export const YELLOW_BRIGHT_OPACITY = 'rgba(250, 194, 109, 0.5)';
