import {P2, Spacing, Text} from '@devipsendigital/react-ui-kit';
import {FC, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import styled, {DefaultTheme} from 'styled-components';

import {BLUE_BUTTON_LIGHT, DARK_GREY_1, DARK_GREY_2} from '../../colors';
import {RootState} from '../../lib/stores/store';

interface ItextBlock {
  title: string;
  value?: string;
  icon?: ReactNode;
  isLink?: boolean;
}

interface ThemeProps {
  theme: DefaultTheme;
  children: ReactNode;
  name: string;
}

export const TextBlock: FC<ItextBlock> = ({title, value, icon, isLink}) => {
  const theme = useSelector((state: RootState) => state.theme.theme as ThemeProps);
  return (
    <WrapperText>
      <WrapperTitle>
        {icon}
        <Spacing width={6}></Spacing>
        <Title color="light-grey" size="xsmall" weight="light">
          {title}
        </Title>
      </WrapperTitle>
      {isLink ? (
        <Link href={value} target="_blank">
          {value}
        </Link>
      ) : (
        <P2 color={theme.name === 'light' ? DARK_GREY_1 : DARK_GREY_2}>{value}</P2>
      )}
    </WrapperText>
  );
};

const WrapperTitle = styled.div`
  color: ${({theme}) => theme.colors.textColor};
  display: flex;
  width: 100%;
  flex-direction: row;
  color: #c6c9d2;
`;

const Title = styled(Text)`
  display: flex;
  justify-content: center;
`;

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  jusify-content: center;
  gap: 5px;
  flex: 1;
`;

const Link = styled.a`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${BLUE_BUTTON_LIGHT};
  cursor: pointer;
`;
