import styled, {CSSObject} from 'styled-components';

export const TextFieldCT = styled.textarea<{placeholderStyles?: CSSObject}>`
  box-shadow: 0px 2px 8px rgba(43, 46, 57, 0.1);
  border: none;
  border-radius: 8px;
  font-size: 12px;
  line-height: 20px;
  word-break: break-all;
  text-wrap: unrestricted;
  color: ${({theme}) => theme.colors.textColor};
  background-color: ${({theme}) => theme.colors.background};
  &::-webkit-input-placeholder {
    ${props => props.placeholderStyles ?? {}};
    font-style: italic;
  }
  &:-ms-input-placeholder {
    ${props => props.placeholderStyles ?? {}};
    font-style: italic;
  }
`;

export const LargeTextField = styled(TextFieldCT)`
  line-height: 40px;
  padding: 0 24px;
  font-size: 20px;
`;
