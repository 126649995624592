import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {LIGHT_GREY_1, Text, P2, Spacing} from '@devipsendigital/react-ui-kit';

import {CustomDiv} from '../../lib/react';
import {DARK_GREY_1} from '../../colors';
import {showModal} from '../../lib/stores/modal';

interface StageListOption {
  identifier: string;
  interventions: string;
  companyName: string;
  conditions: string;
  anticipatedDate?: string;
  actualDate?: string;
  backgroundColor: (string | false)[];
  nbPhase?: number;
  phaseLength: number;
}

export const Stage: CustomDiv<StageListOption> = props => {
  const {
    identifier,
    interventions,
    companyName,
    conditions,
    backgroundColor,
    actualDate,
    anticipatedDate,
    phaseLength,
  } = props;
  const dispatch = useDispatch();

  const handleShowDetails = async (identifier: string) => {
    dispatch(showModal({modalType: 'display-phase-info', param: {body: identifier}}));
  };

  return (
    <Wrapper
      onClick={() => handleShowDetails(identifier)}
      background={backgroundColor}
      phaseLength={phaseLength}
    >
      <Top>
        <TitleContainer>
          {interventions.length > 15 && interventions.split(/[," "]/).length === 1 ? (
            <P2Truncated color={DARK_GREY_1}>{interventions.match(/.{1,10}/g)}</P2Truncated>
          ) : (
            <P2Truncated color={DARK_GREY_1}>{interventions}</P2Truncated>
          )}
        </TitleContainer>
        <DateContainer>
          {actualDate !== '01/1970'
            ? actualDate
            : anticipatedDate !== '01/1970'
            ? anticipatedDate
            : ''}
        </DateContainer>
      </Top>
      <Down>
        <TextBlock>
          <TextTruncatedItalic color="black" size="xsmall" weight="light">
            {companyName}
          </TextTruncatedItalic>
        </TextBlock>
        <Spacing height={1} />
        <TextBlock>
          <TextTruncated color="black" size="xsmall" weight="light">
            {conditions}
          </TextTruncated>
        </TextBlock>
      </Down>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  background: (string | false)[];
  phaseLength: number;
}>`
  background: ${({background}) => background};
  padding: 5px 15px 0px 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px ${({theme}) => (theme.name === 'light' ? LIGHT_GREY_1 : 'none')};
  cursor: pointer;
  height: 70px;
`;

const Top = styled.div`
  display: flex;
`;

const TitleContainer = styled.div`
  margin-right: 1rem;
  margin-bottom: 2px;
  margin-top: 2px;
  flex: 1;
`;

const Down = styled.div`
  height: 80px;
`;

const TextBlock = styled.div`
  color: ${({theme}) => theme.colors.textColor};
`;

const DateContainer = styled(P2)`
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  color: ${({theme}) => theme.colors.textColor};
`;

const P2Truncated = styled(P2)`
  overflow-y: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
  font-size: 11px;
  line-height: 11.58px !important;
`;

const TextTruncated = styled(Text)`
  width: 90%;
  overflow-y: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-size: 11px;
`;

export const TextTruncatedItalic = styled(TextTruncated)`
  font-style: italic;
`;
