import html2canvas from 'html2canvas';
const exportAsImage = async (el: HTMLDivElement | null, imageFileName: string) => {
  if (el !== null) {
    const canvas = await html2canvas(el, {scale: 2});
    const image = canvas.toDataURL('image/png', 1.0);
    downloadImage(image, imageFileName);
  }
};
const downloadImage = (blob: string, fileName: string) => {
  const fakeLink = window.document.createElement('a');
  fakeLink.download = fileName;
  fakeLink.href = blob;
  fakeLink.click();
};

export default exportAsImage;
