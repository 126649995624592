import {Button, getIcon, P1, Spacing} from '@devipsendigital/react-ui-kit';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {
  useGetApiDropdownForAssetQuery,
  useLazyGetFiltersSavedQuery,
  useSearchResultMutation,
} from '../../api/apiGateway';
import {DARK_GREY_2} from '../../colors';
import {ExtraEnum, showModal} from '../../lib/stores/modal';
import {
  setActiveCompaines,
  setActiveCompainesGeo,
  setData,
  setDrugs,
  setFilters,
  setHighestStatus,
  setHighestStatusRegion,
  setIndication,
  setProductGeo,
  setRegulatoryDesignations,
  setStatus,
  setTarget,
  setTherapyArea,
} from '../../lib/stores/search';
import {RootState} from '../../lib/stores/store';
import {DropdownAutocomplete, FeatureName, FieldAutocomplete} from '../core/DropdownAutocomplete';
import {DropdownSelect, FieldNameAsset} from '../core/DropdownSelect';
import {savedSearches} from '../modals/SaveSearchesModal';

interface AssetsFiltersParams {
  searchId?: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AssetsFilters: React.FC<AssetsFiltersParams> = ({searchId, setLoading}) => {
  const [rawOpen, setRawOpen] = useState(true);

  const filterSaved = useSelector((state: RootState) => state.search.filters);

  const {
    indication,
    all_drugs,
    active_companies,
    active_companies_geography,
    therapy_area,
    target_based_actions,
    highest_status_region,
    highest_status,
    status,
    product_geography,
    regulatory_designations,
  } = filterSaved;

  const {data: allAsset} = useGetApiDropdownForAssetQuery();

  const [hasFilterValueSelected, setHasFilterValueSelected] = useState(false);

  const [getFilters] = useLazyGetFiltersSavedQuery();
  const dispatch = useDispatch();
  const [searchResultForAssets] = useSearchResultMutation();

  const displayResult = useCallback(() => {
    setLoading(true);
    searchResultForAssets(filterSaved)
      .unwrap()
      .then(payload => {
        dispatch(setData(payload));
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        dispatch(
          setData({
            total: 0,
            data: [],
            search_id: e.data.type === 'ToManyClauses' ? e.data.type : 'failed',
          })
        );
      });
  }, [dispatch, filterSaved, searchResultForAssets, setLoading]);

  useEffect(() => {
    if (searchId !== undefined) {
      setLoading(true);
      getFilters(searchId)
        .unwrap()
        .then(payload => {
          dispatch(setFilters(payload));
          searchResultForAssets(payload)
            .unwrap()
            .then(payloadData => {
              dispatch(setData(payloadData));
              setLoading(false);
            });
        })
        .catch(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilters = () => {
    dispatch(
      showModal({
        modalType: 'saved-search',
        param: {
          type: savedSearches.SAVED_ASSET,
          body: 'Are you sure you want to reset your search?',
          extra: ExtraEnum.RESET_FILTERS,
        },
      })
    );
  };

  const hasFiltersValue = useCallback(() => {
    const hasValue = Object.values(filterSaved).find((val: string[]) => {
      return val.length > 0;
    });
    return hasValue === undefined ? false : true;
  }, [filterSaved]);

  useEffect(() => {
    setHasFilterValueSelected(hasFiltersValue());
  }, [filterSaved, hasFiltersValue]);

  const handleRawOpened = useCallback(() => setRawOpen(!rawOpen), [rawOpen]);

  const handleIndicationsChange = useCallback(
    (indications: string[]) => dispatch(setIndication(indications)),
    [dispatch]
  );
  const handleDrugsChange = useCallback((drugs: string[]) => dispatch(setDrugs(drugs)), [dispatch]);
  const handleCompaniesChange = useCallback(
    (companies: string[]) => dispatch(setActiveCompaines(companies)),
    [dispatch]
  );
  const handleCompaniesGeographyChange = useCallback(
    (companiesGeography: string[]) => dispatch(setActiveCompainesGeo(companiesGeography)),
    [dispatch]
  );
  const handleTargetsBasedActionChange = useCallback(
    (targetsBasedActions: string[]) => dispatch(setTarget(targetsBasedActions)),
    [dispatch]
  );
  const handleProductGeographyChange = useCallback(
    (productGeography: string[]) => {
      dispatch(setProductGeo(productGeography));
    },
    [dispatch]
  );
  const handleHighestStatusRegionChange = useCallback(
    (highestStatusRegion: string[]) => {
      dispatch(setHighestStatusRegion(highestStatusRegion));
    },
    [dispatch]
  );
  const handleHighestStatusChange = useCallback(
    (highestStatus: string[]) => {
      dispatch(setHighestStatus(highestStatus));
    },
    [dispatch]
  );
  const handleTherapyAreaChange = useCallback(
    (therapies: string[]) => {
      dispatch(setTherapyArea(therapies));
    },
    [dispatch]
  );
  const handleDevelopmentStageChange = useCallback(
    (developmentStage: string[]) => dispatch(setStatus(developmentStage)),
    [dispatch]
  );
  const handleRegulatoryDesignations = useCallback(
    (developmentStage: string[]) => dispatch(setRegulatoryDesignations(developmentStage)),
    [dispatch]
  );

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    if (hasFiltersValue()) {
      window.addEventListener('beforeunload', alertUser);
      return () => {
        window.removeEventListener('beforeunload', alertUser);
      };
    }
    return;
  }, [hasFiltersValue]);

  return (
    <Wrapper>
      <WrapperFilters isExpanded={rawOpen}>
        <WrapperFiltersContainer>
          <Filter>
            <DropdownAutocomplete
              selected={all_drugs}
              placeholder={'Drug name'}
              field={FieldAutocomplete.ALL_DRUGS_PROCESSED}
              onChange={handleDrugsChange}
              feature={FeatureName.ASSET}
            />
          </Filter>
          <Filter>
            <DropdownAutocomplete
              selected={active_companies}
              placeholder={'Active company'}
              field={FieldAutocomplete.ACTIVE_COMPAGNIES_PROCESSED}
              onChange={handleCompaniesChange}
              feature={FeatureName.ASSET}
            />
          </Filter>
          <Filter>
            <DropdownSelect
              selected={therapy_area}
              onChange={handleTherapyAreaChange}
              data={allAsset?.[FieldNameAsset.THERAPY_AREA] ?? []}
              placeholder={'Therapeutic Area'}
              addSelectAll={true}
            />
          </Filter>
          <Filter>
            <DropdownAutocomplete
              selected={indication}
              placeholder={'Indication'}
              field={FieldAutocomplete.INDICATION}
              onChange={handleIndicationsChange}
              feature={FeatureName.ASSET}
            />
          </Filter>
          {rawOpen && (
            <Filter>
              <ActionContainer>
                <Button
                  onClick={displayResult}
                  typeButton={'primary'}
                  size="medium"
                  children={'Search'}
                  disabled={!hasFiltersValue()}
                ></Button>

                {hasFilterValueSelected && (
                  <WrapperReset onClick={resetFilters}>Reset All</WrapperReset>
                )}
              </ActionContainer>
            </Filter>
          )}
        </WrapperFiltersContainer>
        {!rawOpen && (
          <WrapperFiltersContainer>
            <Filter>
              <DropdownSelect
                selected={status}
                onChange={handleDevelopmentStageChange}
                data={allAsset?.[FieldNameAsset.DEVELOPMENT_STAGE] ?? []}
                placeholder="Development stage"
                addSelectAll={true}
              />
            </Filter>
            <Filter>
              <DropdownAutocomplete
                selected={product_geography}
                placeholder={'Product geography'}
                field={FieldAutocomplete.PRODUCT_GEOGRAPHY}
                onChange={handleProductGeographyChange}
                feature={FeatureName.ASSET}
                defaultValue={allAsset?.[FieldNameAsset.PRODUCT_GEOGRAPHY] ?? []}
                addSelectAll={true}
              />
            </Filter>
            <Filter>
              <DropdownSelect
                selected={highest_status}
                onChange={handleHighestStatusChange}
                data={allAsset?.[FieldNameAsset.HIGHEST_STATUS] ?? []}
                placeholder={'Highest status'}
                addSelectAll={true}
              />
            </Filter>
            <Filter>
              <DropdownSelect
                selected={highest_status_region}
                onChange={handleHighestStatusRegionChange}
                data={allAsset?.[FieldNameAsset.HIGHEST_STATUS_REGION] ?? []}
                placeholder={'Highest status region'}
                addSelectAll={true}
              />
            </Filter>
          </WrapperFiltersContainer>
        )}
        {!rawOpen && (
          <WrapperFiltersContainer>
            <Filter>
              <DropdownAutocomplete
                selected={active_companies_geography}
                placeholder={'Active company location'}
                field={FieldAutocomplete.ACTIVE_COMPAGNIES_GEOGRAPHY}
                onChange={handleCompaniesGeographyChange}
                feature={FeatureName.ASSET}
                defaultValue={allAsset?.[FieldNameAsset.ACTIVE_COMPAGNIES_GEOGRAPHY] ?? []}
                addSelectAll={true}
              />
            </Filter>
            <Filter>
              <DropdownAutocomplete
                selected={target_based_actions}
                placeholder={'MoA'}
                field={FieldAutocomplete.TARGET_GD}
                onChange={handleTargetsBasedActionChange}
                feature={FeatureName.ASSET}
              />
            </Filter>
            <Filter>
              <DropdownSelect
                selected={regulatory_designations}
                onChange={handleRegulatoryDesignations}
                data={allAsset?.[FieldNameAsset.REGULATORY_DESIGNATIONS] ?? []}
                placeholder={'Regulatory designations'}
                addSelectAll={true}
              />
            </Filter>
            <Filter>
              <ActionContainer>
                <Button
                  onClick={displayResult}
                  typeButton={'primary'}
                  size="medium"
                  children={'Search'}
                  disabled={!hasFiltersValue()}
                ></Button>
                <WrapperReset onClick={resetFilters}>Reset All</WrapperReset>
              </ActionContainer>
            </Filter>
          </WrapperFiltersContainer>
        )}
      </WrapperFilters>
      <DisplayFilters onClick={handleRawOpened}>
        <P1>{rawOpen ? 'More filters' : 'Less filters'}</P1>
        {rawOpen ? (
          <ArrowDown onClick={handleRawOpened}>{getIcon('expandableArrowDown', 50)}</ArrowDown>
        ) : (
          <ArrowUp onClick={handleRawOpened}>{getIcon('expandableArrowUp', 50)}</ArrowUp>
        )}
      </DisplayFilters>
      <Spacing height={10} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const WrapperFilters = styled.div<{isExpanded: boolean}>`
  display: flex;
  flex-direction: column;
  height: ${({isExpanded}) => (isExpanded ? '130px' : '300px')};
  background: ${({theme}) => theme.inputSelect.colors.background};
  border: 1px solid #c6c9d2;
  border-radius: 16px;
  justify-content: space-evenly;
`;

const WrapperFiltersContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 30px 0;
  gap: 20px;
`;

const Filter = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const DisplayFilters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  width: 164px;
  height: '40px';
  left: calc(50% - 164px / 2);
  top: -20px;
  background: ${({theme}) => theme.inputSelect.colors.background};
  border-radius: 30px;
  cursor: pointer;
`;

const ArrowDown = styled.div`
  background: ${DARK_GREY_2};
  border-radius: 27px;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.switch.toggle.colors.normal};
  }
`;

const ArrowUp = styled.div`
  background: ${({theme}) => theme.switch.toggle.colors.normal};
  border-radius: 27px;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.switch.toggle.colors.normal};
  }
`;

const WrapperReset = styled.div`
  color: ${({theme}) => theme.colors.textColor};
  box-sizing: border-box;
  font-size: 15px;
  margin-top: 1.2rem;
  margin-left: 5.5rem;
  cursor: pointer;
  text-decoration: underline;
`;

const ActionContainer = styled.div``;
