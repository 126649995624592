import styled from 'styled-components';
import {WHITE} from '@devipsendigital/react-ui-kit';
import React, {MouseEventHandler} from 'react';

interface CellWrapperProps {
  value: string[];
  color: string;
  backgroundColor?: string;
  width?: string;
  fontWeight?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
}

export const CellWrapperGeneric: React.FC<CellWrapperProps> = props => {
  const {value, color, fontWeight, backgroundColor, children, onClick} = props;
  return (
    <CellWrapper
      color={color}
      backgroundColor={backgroundColor !== undefined ? backgroundColor : ''}
      fontWeight={fontWeight !== undefined ? fontWeight : ''}
      onClick={onClick}
    >
      {value}
      {children}
    </CellWrapper>
  );
};

const CellWrapper = styled.div<{
  color: string;
  fontWeight: string;
  backgroundColor: string;
}>`
  color: ${({color}) => color};
  background: ${({backgroundColor}) => backgroundColor};
  font-weight: ${({fontWeight}) => fontWeight};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 10px;
  cursor: pointer;
  font-size: 12px;
`;

export const CellWrapperGenericScrolled: React.FC<CellWrapperProps> = props => {
  const {value, color, fontWeight, backgroundColor, children, onClick} = props;
  return (
    <CellWrapperScrolled
      color={color}
      backgroundColor={backgroundColor !== undefined ? backgroundColor : ''}
      fontWeight={fontWeight !== undefined ? fontWeight : ''}
      onClick={onClick}
    >
      {value}
      {children}
    </CellWrapperScrolled>
  );
};

const CellWrapperScrolled = styled.div<{
  color: string;
  fontWeight: string;
  backgroundColor: string;
}>`
  color: ${({color}) => color};
  background: ${({backgroundColor}) => backgroundColor};
  font-weight: ${({fontWeight}) => fontWeight};
  font-size: 12px;
  padding-top: 8px;
  height: 60px;
  overflow: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 10px;
`;

export const CellWrapperGenericPin: React.FC<CellWrapperProps> = props => {
  const {value, color, fontWeight, backgroundColor, children, onClick} = props;
  const elements = value.toString().split(',');
  return (
    <CellWrapperPin
      color={color}
      backgroundColor={backgroundColor !== undefined ? backgroundColor : ''}
      fontWeight={fontWeight !== undefined ? fontWeight : ''}
      onClick={onClick}
    >
      {value.length <= 2
        ? value
        : elements.map((element: string, index) => {
            return element !== '' && <p key={`${index}-${element}`}>{`\u2022 ${element}`}</p>;
          })}
      {children}
    </CellWrapperPin>
  );
};

const CellWrapperPin = styled.div<{
  color: string;
  fontWeight: string;
  backgroundColor: string;
}>`
  color: ${({color}) => color};
  background: ${({backgroundColor}) => backgroundColor};
  font-weight: ${({fontWeight}) => fontWeight};
  font-size: 12px;
  padding-top: 8px;
  height: 50px;
  overflow: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 10px;
`;

export const CellWrapperBlueBordered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.table.header.cells.background};
  color: ${WHITE};
  padding: 2px 10px 2px;
  height: 100%;
  border-radius: 40px;
`;

export const CellWrapperContainerBlue = styled.div`
  color: ${({theme}) => theme.table.header.cells.background};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

export const CellWrapperPosStartEnd = styled.div`
  padding-left: 10px;
`;
