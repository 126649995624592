import {Button, Spacing} from '@devipsendigital/react-ui-kit';
import {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {DARK_GREY_3} from '../../colors';
import {setModalInfo, setMoreData} from '../../lib/stores/clinical';
import {ParamsModal, hideModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';
import {ExitFromPhase} from '../../svg/exitFromPhase';

import {ModalActions, ModalContent, ModalTitle, WrapperExit} from './ModalFragments';
import {Body} from './SaveSearchesForm';

export enum savedSearches {
  SAVED_CT = 'ct',
  SAVED_ASSET = 'asset',
}

interface SaveSeachedModalProps {
  param: ParamsModal;
}

export const InfoModal: FC<SaveSeachedModalProps> = ({param}) => {
  const [, setSavingState] = useState<'saving' | 'success' | Error>('saving');
  const dispatch = useDispatch();

  const {show} = useSelector((state: RootState) => state.modal);

  useEffect(() => setSavingState('saving'), []);

  const handleHideModal = useCallback(() => {
    if (show === true) {
      dispatch(hideModal());
    }
  }, [dispatch, show]);

  const handleDisplayResults = useCallback(() => {
    const {data} = param;
    if (data !== undefined) {
      dispatch(setMoreData(data));
      dispatch(setModalInfo(true));
    }
    dispatch(hideModal());
  }, [dispatch, param]);

  return (
    <>
      <ModalActions>
        <ModalTitle>Download limit</ModalTitle>
        <WrapperExit>
          <ExitFromPhase color={DARK_GREY_3} size={25} onClick={handleHideModal} />
        </WrapperExit>
      </ModalActions>
      <ModalContent>
        <Spacing height={32} />
        {param?.body !== undefined && (
          <>
            <Body>{param?.body}</Body>
            <Spacing height={32} />
          </>
        )}

        <Spacing height={72} />
        <ModalActions>
          <Button typeButton="secondary" onClick={handleHideModal} size="small">
            Cancel
          </Button>
          <Button typeButton="primary" onClick={handleDisplayResults} size="small">
            Display more results
          </Button>
        </ModalActions>
      </ModalContent>
    </>
  );
};
