import {
  ActiveCompanies,
  DropdownCTResponseProps,
  DropdownResponseProps,
  FilterTypes,
  IDataSearchResult,
  StatusesDef,
  ISearchResults,
  Indications,
  MergedSavedDataProps,
  PhaseInfoBack,
  ResponsePropsForAsset,
  ResponsePropsForClinical,
  SavedDataMergedProps,
  SavedSearchResult,
  SavedSearchesType,
  SearchCTDataProps,
  SearchDataProps,
  SearchResultDataProps,
} from '../lib/models';

export function parseDropDownForAssetQuery(raw: ResponsePropsForAsset): DropdownResponseProps {
  const {
    highest_status: highestStatus,
    highest_status_region: highestStatusRegion,
    therapy_area: therapies,
    product_geography: productGeography,
    status: developmentStage,
    active_companies_geography: companiesGeography,
    regulatory_designations: regulatoryDesignations,
  } = raw;
  return {
    highestStatus,
    highestStatusRegion,
    therapies,
    productGeography,
    developmentStage,
    companiesGeography,
    regulatoryDesignations,
  };
}

export function parseDropDownForClinicalQuery(
  raw: ResponsePropsForClinical
): DropdownCTResponseProps {
  const {
    overall_status: status,
    processed_phase: phases,
    gender,
    age_group: age,
    published_results: study,
    lead_sponsor_funding: funder,
    healthy_volunteers: healthy,
  } = raw;
  status.unshift('- Select All -');
  return {
    status,
    phases,
    gender,
    age,
    study,
    funder,
    healthy,
  };
}

export function alphabeticalOrder(s1: string, s2: string): number {
  return s1.localeCompare(s2);
}

export const parseMapDropdownTypes = (elems: string[]) => {
  return elems.map((elem: string) => {
    return {label: elem, value: elem};
  });
};

export const parseMapfilterTypes = (elem: FilterTypes) => {
  return elem.value !== undefined ? [elem.value] : [];
};

export function parseSearchResultResponse(raw: SearchDataProps): ISearchResults {
  const {search_id, results, total} = raw;
  const data = results.map((result: SearchResultDataProps) => {
    const {
      asset_id,
      company,
      drug,
      drug_aliases,
      active_companies_obj,
      therapy_area,
      target_based_actions,
      drug_category,
      indication_obj,
    } = result;

    const row = {
      searchId: '',
      assetId: '',
      company: '',
      drug: '',
      aliases: '',
      companies: '',
      geography: '',
      therapy: '',
      targetcortellis: '',
      designations: '',
      category: '',
      indications: {indication: '', src: ''},
      status: '',
      region: '',
      product: {productGeography: '', src: ''},
      stage: {stage: '', src: ''},
      regulatory: {regulatory: '', src: ''},
      subRows: [],
    };

    let indicationRows = [] as IDataSearchResult[];
    let subRows = [] as IDataSearchResult[];

    // copy indication_obj && remove indication_obj[0].status_obj[0]
    const copyIndicationWithSpread = JSON.parse(JSON.stringify(indication_obj));
    copyIndicationWithSpread[0].status_obj.splice(0, 1);

    copyIndicationWithSpread.forEach((element: Indications, index: number) => {
      let lastIndication = '';
      let highestStatus = '';
      let lastHighestStatusRegion = '';
      let lastRegulatoryDesignation = '';
      if (index > 0) {
        lastIndication = '';
        highestStatus = '';
        lastHighestStatusRegion = '';
      } else {
        lastIndication = element.indication;
        highestStatus = element.highest_status;
        lastHighestStatusRegion = element.highest_status_region.join(', ');
        lastRegulatoryDesignation = element.regulatory_designations.join(', ');
      }

      return element.status_obj.forEach((elementStatus: StatusesDef) => {
        indicationRows.push({
          searchId: '',
          assetId: '',
          company: '',
          drug: '',
          aliases: '',
          companies: '',
          geography: '',
          therapy: '',
          targetcortellis: '',
          designations: '',
          category: '',
          indications: {
            indication:
              index > 0 && lastIndication !== element.indication ? element.indication : '',
            src: element.src,
          },
          status: {
            status: highestStatus !== element.highest_status ? element.highest_status : '',
            src: element.src,
          },
          region: {
            region:
              lastHighestStatusRegion !== element.highest_status_region.join(', ')
                ? element.highest_status_region[0].length > 0
                  ? element.highest_status_region.join(', ')
                  : ''
                : '',
            src: element.src,
          },
          product: {
            productGeography: elementStatus.product_geography,
            src: element.src,
          },
          stage: {
            stage: elementStatus.development_stage,
            src: element.src,
          },
          regulatory: {
            regulatory:
              lastRegulatoryDesignation !== element.regulatory_designations.join(', ')
                ? element.regulatory_designations[0].length > 0
                  ? element.regulatory_designations.join(', ')
                  : ''
                : '',
            src: element.src,
          },
        });
        lastIndication = element.indication;
        highestStatus = element.highest_status;
        lastHighestStatusRegion = element.highest_status_region.join(', ');
        lastRegulatoryDesignation = element.regulatory_designations.join(', ');
      });
    });

    const activeCompaniesSubRows = active_companies_obj.slice(1).map((company: ActiveCompanies) => {
      return {
        searchId: search_id,
        assetId: asset_id,
        company: '',
        drug: '',
        aliases: '',
        companies: company.active_company,
        geography: company.active_company_geography,
        therapy: '',
        targetcortellis: '',
        designations: '',
        category: '',
        indications: {indication: '', src: ''},
        status: '',
        region: '',
        product: {productGeography: '', src: ''},
        stage: {stage: '', src: ''},
        regulatory: {regulatory: '', src: ''},
        subRows: [],
      };
    });

    if (activeCompaniesSubRows.length < indicationRows.length) {
      subRows = activeCompaniesSubRows.map((elem, index) => {
        const {indications, status, region, product, stage, subRows} = indicationRows[index];
        return {...elem, indications, status, region, product, stage, subRows};
      });
      subRows = [...subRows, ...indicationRows.splice(activeCompaniesSubRows.length)];
    } else {
      subRows = activeCompaniesSubRows.map((elem, index) => {
        if (indicationRows[index] !== undefined) {
          const {indications, status, region, product, stage, regulatory, subRows} =
            indicationRows[index];
          return {...elem, indications, status, region, product, stage, regulatory, subRows};
        } else {
          return {
            ...elem,
            indications: {indication: '', src: ''},
            status: {status: '', src: ''},
            region: {region: '', src: ''},
            product: {productGeography: '', src: ''},
            stage: {stage: '', src: ''},
            regulatory: {regulatory: '', src: ''},
          };
        }
      });
    }

    const RowObj = {
      searchId: search_id,
      assetId: asset_id,
      company: company,
      drug: drug,
      aliases: drug_aliases !== undefined ? drug_aliases.join(', ') : drug_aliases,
      companies: active_companies_obj[0].active_company,
      geography: active_companies_obj[0].active_company_geography,
      therapy: therapy_area.join(', '),
      targetcortellis:
        target_based_actions !== undefined ? target_based_actions.join(', ') : target_based_actions,
      category: drug_category !== undefined ? drug_category.join(', ') : drug_category,
      indications: {
        indication: indication_obj[0].indication,
        src: indication_obj[0].src,
      },
      status: {
        status: indication_obj[0].highest_status,
        src: indication_obj[0].src,
      },
      region: {
        region:
          indication_obj[0].highest_status_region.length > 0
            ? indication_obj[0].highest_status_region.join(', ')
            : '',
        src: indication_obj[0].src,
      },
      product: {
        productGeography: indication_obj[0].status_obj[0].product_geography,
        src: indication_obj[0].src,
      },
      stage: {
        stage: indication_obj[0].status_obj[0].development_stage,
        src: indication_obj[0].src,
      },
      regulatory: {
        regulatory:
          indication_obj[0].regulatory_designations.length > 0
            ? indication_obj[0].regulatory_designations.join(', ')
            : '',
        src: indication_obj[0].src,
      },
      subRows: subRows,
    };

    return {
      ...row,
      ...RowObj,
    };
  });
  return {data, total, search_id};
}

export function parseSearchCTResultResponse(raw: SearchCTDataProps): SearchCTDataProps {
  Object.keys(raw.results).forEach(key => {
    if (raw.results[key as keyof typeof raw.results].length === 0) {
      delete raw.results[key as keyof typeof raw.results];
    }
  });
  for (let key in raw.results) {
    let phase = raw.results[key as keyof typeof raw.results] as unknown as PhaseInfoBack[];
    const phaseTemp = phase.map((element: PhaseInfoBack) => {
      const interventions = element.interventions.reduce((result: string, item: string) => {
        return `${result}, ${item}`;
      });
      const lead_sponsor = element.lead_sponsor.reduce((result: string, item: string) => {
        return `${result}, ${item}`;
      });
      const conditions = element.conditions.reduce((result: string, item: string) => {
        return `${result}, ${item}`;
      });
      return {...element, interventions, lead_sponsor, conditions};
    });
    raw.results[key as keyof typeof raw.results] = phaseTemp;
  }
  return raw;
}

export function parseSavedData(raw: SavedDataMergedProps[]): MergedSavedDataProps[] {
  return raw.map((elem: SavedDataMergedProps) => {
    const {search_id, name, saved_at, saved_search_id} = elem;
    return {
      id: search_id,
      name,
      date: saved_at.split(' ')[0],
      saved_search_id,
    };
  });
}
export function parseSavedResult(raw: SavedSearchResult): SavedSearchesType {
  const asset = parseSavedData(raw.asset_landscape);
  const clinical = parseSavedData(raw.ct_landscape);
  return {asset, clinical};
}

export function isObjectEmpty(obj: any) {
  let count = 0;
  for (const key in obj) {
    if (obj[key] === undefined) return;
    if (obj[key] !== null && obj[key].length !== 0) {
      count++;
    }
  }
  return count;
}

export const generateKey = (pre: string) => {
  return `${pre}_${new Date().getTime()}`;
};
