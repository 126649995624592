import {CustomSvg} from '../lib/react';

interface ExitProps {
  size: number;
  color?: string;
}

export const Tick: CustomSvg<ExitProps> = ({size, color, ...rest}) => (
  <svg
    width={size}
    height={size}
    {...(color === undefined ? {} : {fill: 'none'})}
    {...rest}
    viewBox="0 0 35 35"
  >
    <path
      d="M29 14L18.9996 24.483C18.2117 25.309 16.8933 25.309 16.1054 24.483L11 19.1313"
      stroke={color}
      strokeWidth="3"
    />
  </svg>
);
