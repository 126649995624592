import {CustomSvg} from '../lib/react';

interface ExitProps {
  size: number;
  color?: string;
}

export const ExitFromPhase: CustomSvg<ExitProps> = ({size, color, ...rest}) => (
  <svg
    width={size}
    height={size}
    {...(color === undefined ? {} : {fill: 'none'})}
    {...rest}
    viewBox="0 0 22 29"
  >
    <rect x="1.2" y="1" width="22" height="22" rx="11.5" stroke={color} strokeWidth="2" />
    <path
      d="M7.29289 8.70711L16.2929 17.7071L17.7071 16.2929L8.70711 7.29289L7.29289 8.70711Z"
      fill={color}
      strokeWidth="3"
    />
    <path
      d="M16.2929 7.29289L7.29289 16.2929L8.70711 17.7071L17.7071 8.70711L16.2929 7.29289Z"
      fill={color}
      strokeWidth="3"
    />
  </svg>
);
