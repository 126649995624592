import {CustomSvg} from '../lib/react';

interface HalexLogoProps {
  size: number;
}

export const HalexLogo: CustomSvg<HalexLogoProps> = ({size, color, ...rest}) => (
  <svg width={size} height={size / 5.06} fill="none" {...rest} viewBox="0 0 160 32">
    <g clipPath="url(#clip0_1038_33182)">
      <path
        d="M23.8216 31.2347H16.7233V19.3331H7.13538V31.2347H0V1.61963H7.13538V12.9104H16.7233V1.62888H23.8216V31.2347Z"
        fill={color}
      />
      <path
        d="M44.691 31.2347L44.7604 29.2634C43.5018 30.8521 41.7403 31.645 39.476 31.6419C37.5406 31.6904 35.656 31.0194 34.1869 29.7585C33.4925 29.1853 32.9363 28.4627 32.5597 27.6447C32.1832 26.8267 31.996 25.9344 32.0121 25.034C32.0121 22.6463 32.8928 20.8432 34.6543 19.6246C36.4158 18.4061 38.9778 17.7891 42.3403 17.7737H44.4596V16.6307C44.4596 14.5993 41.2205 14.0024 38.9901 15.5711L35.6307 11.2723C43.4231 5.47881 51.3637 10.6337 51.3637 16.5104V31.2347H44.691ZM41.1233 26.76C41.8694 26.7888 42.6076 26.5991 43.2473 26.214C43.7481 25.9137 44.1651 25.492 44.4596 24.9877V21.9337H42.4653C40.0775 21.9337 38.8837 22.5445 38.8837 24.7101C38.8742 24.9931 38.9259 25.2748 39.0353 25.5359C39.1448 25.7971 39.3093 26.0315 39.5176 26.2232C39.9642 26.6015 40.5391 26.7937 41.1233 26.76Z"
        fill={color}
      />
      <path d="M67.3512 31.2347H60.4749V2.31368L67.3512 0V31.2347Z" fill={color} />
      <path
        d="M87.2118 31.6418C83.84 31.6418 81.1083 30.6346 79.0167 28.6202C76.9251 26.6057 75.8809 23.9836 75.884 20.7537V20.1938C75.8433 18.1376 76.2722 16.0992 77.138 14.2337C77.9228 12.5652 79.1939 11.1731 80.7844 10.2403C82.52 9.26149 84.4887 8.7721 86.4806 8.82434C89.5655 8.82434 92.0057 9.78375 93.8011 11.7026C95.5965 13.6214 96.4911 16.296 96.485 19.7264V22.3871H82.862C83.0426 23.5145 83.6103 24.5437 84.4677 25.2977C85.3709 26.0367 86.513 26.4202 87.6791 26.3759C89.8386 26.3759 91.5352 25.617 92.7692 24.0992L95.9019 27.8011C94.9636 29.0471 93.7096 30.02 92.2695 30.6192C90.6747 31.3133 88.9509 31.6618 87.2118 31.6418ZM86.439 14.1134C84.4353 14.1134 83.2415 15.3026 82.862 17.9587H89.7707V17.5839C89.8126 17.1239 89.7603 16.6602 89.6171 16.221C89.4739 15.7819 89.2428 15.3765 88.9378 15.0296C88.6069 14.7129 88.2141 14.4681 87.7841 14.3104C87.3541 14.1527 86.8962 14.0856 86.439 14.1134Z"
        fill={color}
      />
      <path
        d="M113.157 15.2935L116.346 9.25476H123.467L117.669 19.9486L123.749 31.2347H116.609L113.153 24.7286L109.738 31.2347H102.542L108.641 19.9486L102.866 9.25476H110.066L113.157 15.2935Z"
        fill={color}
      />
      <path d="M135.785 9.23157L123.897 31.2346H116.54L128.664 9.23157" fill="#377FD3" />
      <path d="M143.621 17L136.007 31.2346H128.65L136.5 17" fill="#377FD3" />
      <circle cx="145.5" cy="28" r="3.5" fill="#377FD3" />
    </g>
    <defs>
      <clipPath id="clip0_1038_33182">
        <rect width="160" height="31.6418" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
