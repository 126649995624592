import {createGlobalStyle} from 'styled-components';

export const CssReset = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-size: 1em;
    outline: none;
    box-sizing: border-box;
  }
  code,
  pre,
  textarea,
  input,
  select,
  button {
    font: inherit;
    font-size: 1em;
  }

  em {
    font-style: normal;
  }

  th,
  b,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  a,
  img,
  a img,
  iframe,
  form,
  fieldset,
  table {
    border: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  th,
  td {
    text-align: left;
    vertical-align: center;
  }

  body {
    line-height: 1;
    background: white;
    color: #ffffff;
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
  }

  a {
    color: inherit;
  }

  label {
    user-select: none;
  }
`;
