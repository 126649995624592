import {CustomSvg} from '../lib/react';

interface PenProps {
  size: number;
  color?: string;
}

export const Pen: CustomSvg<PenProps> = ({size, color, ...rest}) => (
  <svg
    width={size}
    height={size}
    {...(color === undefined ? {} : {fill: color})}
    {...rest}
    viewBox="0 0 18 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.84451 2.57246C9.65416 2.3821 9.34555 2.3821 9.1552 2.57244L9.0737 2.65394C9.11885 3.01189 9.405 3.29809 9.76294 3.34332L9.8445 3.26175C10.0348 3.07141 10.0348 2.7628 9.84451 2.57246ZM8.6216 4.48466C8.34595 4.30654 8.1104 4.07098 7.9323 3.79532L5.4503 6.27729C4.96706 6.76052 4.78537 6.94541 4.63941 7.14844C4.50258 7.33878 4.38675 7.54336 4.29393 7.75862C4.23292 7.90012 4.1848 8.04972 4.11806 8.29886C4.3672 8.23212 4.5168 8.184 4.65831 8.12298C4.87356 8.03016 5.07814 7.91433 5.26847 7.77751C5.47151 7.63156 5.65639 7.44986 6.13962 6.96664L8.6216 4.48466ZM8.09454 1.51178C8.87069 0.735641 10.1291 0.73566 10.9052 1.51182C11.6813 2.28796 11.6813 3.54629 10.9052 4.32242L7.20028 8.0273L7.15619 8.07139C6.7317 8.49598 6.45797 8.76978 6.14402 8.99547C5.86583 9.19544 5.56683 9.36473 5.25224 9.50039C4.89719 9.65348 4.52156 9.74734 3.93909 9.89288L3.93907 9.89289L3.87859 9.908L3.26503 10.0614C3.00945 10.1253 2.73908 10.0504 2.5528 9.86412C2.36651 9.67783 2.29163 9.40747 2.35552 9.15189L2.50891 8.53834L2.52402 8.47785C2.66957 7.89536 2.76343 7.51974 2.91653 7.16468C3.05218 6.85008 3.22148 6.55108 3.42146 6.2729C3.64715 5.95894 3.92095 5.6852 4.34555 5.26072L4.38965 5.21663L8.09454 1.51178ZM0.583252 12.2505C0.583252 11.8362 0.919038 11.5005 1.33325 11.5005H10.6666C11.0808 11.5005 11.4166 11.8362 11.4166 12.2505C11.4166 12.6647 11.0808 13.0005 10.6666 13.0005H1.33325C0.919038 13.0005 0.583252 12.6647 0.583252 12.2505Z"
      fill={color}
    />
  </svg>
);
