import {CustomSvg} from '../lib/react';

interface ExitProps {
  size: number;
  color?: string;
}

export const Exit: CustomSvg<ExitProps> = ({size, color, ...rest}) => (
  <svg
    width={size}
    height={size}
    {...(color === undefined ? {} : {fill: color})}
    {...rest}
    viewBox="0 0 25 26"
  >
    <rect x="2.12109" y="0.5" width="32" height="3" rx="1.5" transform="rotate(45 2.12109 0.5)" />
    <rect
      x="24.855"
      y="2.72656"
      width="32"
      height="3"
      rx="1.5"
      transform="rotate(135 24.855 2.72656)"
    />
  </svg>
);
