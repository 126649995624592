import {CustomSvg} from '../lib/react';

interface MedicalProps {
  size: number;
}

export const Medical: CustomSvg<MedicalProps> = ({size, color, ...rest}) => (
  <svg width={size} height={size} {...rest} viewBox="0 0 16 16" fill={'none'}>
    <rect
      x="1.33325"
      y="1.33203"
      width="13.3333"
      height="13.3333"
      rx="2.00177"
      stroke="currentColor"
      strokeWidth="1.50133"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.34158 6.67134V4.33594H6.67256L6.67256 6.67134H4.33716V9.34036H6.67256L6.67256 11.6758H9.34158V9.34036H11.677V6.67134H9.34158Z"
      stroke="currentColor"
      strokeWidth="1.50133"
      strokeLinejoin="round"
    />
  </svg>
);
