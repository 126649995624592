import {CustomSvg} from '../lib/react';

interface EmployeesProps {
  size: number;
}

export const Employees: CustomSvg<EmployeesProps> = ({size, color, ...rest}) => (
  <svg width={size} height={size} {...rest} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41675 4.00065C4.41675 2.94211 5.27487 2.08398 6.33341 2.08398C7.39196 2.08398 8.25008 2.94211 8.25008 4.00065C8.25008 5.0592 7.39196 5.91732 6.33341 5.91732C5.27487 5.91732 4.41675 5.0592 4.41675 4.00065ZM6.33341 0.583984C4.44644 0.583984 2.91675 2.11368 2.91675 4.00065C2.91675 5.88762 4.44644 7.41732 6.33341 7.41732C8.22039 7.41732 9.75008 5.88762 9.75008 4.00065C9.75008 2.11368 8.22039 0.583984 6.33341 0.583984ZM11.0001 0.583984C10.5859 0.583984 10.2501 0.919771 10.2501 1.33398C10.2501 1.7482 10.5859 2.08398 11.0001 2.08398C12.0586 2.08398 12.9167 2.94211 12.9167 4.00065C12.9167 5.0592 12.0586 5.91732 11.0001 5.91732C10.5859 5.91732 10.2501 6.2531 10.2501 6.66732C10.2501 7.08153 10.5859 7.41732 11.0001 7.41732C12.8871 7.41732 14.4167 5.88762 14.4167 4.00065C14.4167 2.11368 12.8871 0.583984 11.0001 0.583984ZM0.916748 12.534C0.916748 10.3525 2.68522 8.58398 4.86675 8.58398H7.80008C9.98161 8.58398 11.7501 10.3525 11.7501 12.534C11.7501 14.1264 10.4592 15.4173 8.86675 15.4173H3.80008C2.20766 15.4173 0.916748 14.1264 0.916748 12.534ZM4.86675 10.084C3.51365 10.084 2.41675 11.1809 2.41675 12.534C2.41675 13.298 3.03609 13.9173 3.80008 13.9173H8.86675C9.63074 13.9173 10.2501 13.298 10.2501 12.534C10.2501 11.1809 9.15318 10.084 7.80008 10.084H4.86675ZM12.3334 8.58398C11.9192 8.58398 11.5834 8.91977 11.5834 9.33398C11.5834 9.7482 11.9192 10.084 12.3334 10.084H13.1334C14.4865 10.084 15.5834 11.1809 15.5834 12.534C15.5834 13.298 14.9641 13.9173 14.2001 13.9173H12.3334C11.9192 13.9173 11.5834 14.2531 11.5834 14.6673C11.5834 15.0815 11.9192 15.4173 12.3334 15.4173H14.2001C15.7925 15.4173 17.0834 14.1264 17.0834 12.534C17.0834 10.3525 15.3149 8.58398 13.1334 8.58398H12.3334Z"
      fill="currentColor"
    />
  </svg>
);
