import React, {ReactNode} from 'react';
import styled, {DefaultTheme} from 'styled-components';
import {useSelector} from 'react-redux';
import {WHITE} from '@devipsendigital/react-ui-kit';

import {RootState} from '../../lib/stores/store';
import {BLUE_HALEX_MAIN, DARK_GREY_1} from '../../colors';
import {Card} from '../../components/home/Card';
import {Spacing} from '../../components/core/Spacing';
import {HalexLogo} from '../../svg/halex_logo';

interface ThemeProps {
  theme: DefaultTheme;
  children: ReactNode;
  name: string;
}

export const HomeScreen: React.FC = () => {
  const options = [
    {
      title: 'Referential Assets',
      button: 'Search for Assets',
      url: '/assets',
      text: 'Search and explore aggregated asset data to identify and evaluate asset opportunities.',
    },
    {
      title: 'Referential Clinical Trials',
      button: 'Search for Clinical Trials',
      url: '/clinical-trials',
      text: 'Search and explore aggregated Clinical study data to see the evidence available and evaluate opportunities.',
    },
  ];

  const theme = useSelector((state: RootState) => state.theme.theme as ThemeProps);

  return (
    <Wrapper>
      <BackgroundImg></BackgroundImg>
      <Top>
        <HalexLogo size={280} color={theme.name === 'light' ? DARK_GREY_1 : WHITE}></HalexLogo>
        <Spacing height={20} />
        <Description>
          Use the power of external datasets to execute asset screening tasks in an effective and
          efficient way.
        </Description>
      </Top>
      <Down>
        {options.map(({title, button, url, text}) => (
          <Card key={title} title={title} button={button} url={url} text={text} />
        ))}
      </Down>
      <Spacing height={50} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const BackgroundImg = styled.div`
  position: absolute;
  background: url('background-halex.png');
  width: 100%;
  height: 581px;
  z-index: -1;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8.8vh 32vh 8.8vh 32vh;
`;
const Down = styled.div`
  display: flex;
  justify-content: center;
`;
const Description = styled.div`
  width: 55.75rem;
  height: 70px;
  color: ${BLUE_HALEX_MAIN};
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  line-height: 35.16px;
  text-align: center;
  text-transform: uppercase;
`;
