import {CustomSvg} from '../lib/react';

interface IconProps {
  size: number;
  color?: string;
}

export const Icon: CustomSvg<IconProps> = ({size, color, ...rest}) => (
  <svg
    width={size}
    height={size}
    {...(color === undefined ? {} : {fill: color})}
    {...rest}
    viewBox="0 0 17 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6131 0.75L5.625 0.75H6C6.41421 0.75 6.75 1.08579 6.75 1.5C6.75 1.91421 6.41421 2.25 6 2.25H5.625C5.50449 2.25 5.4578 2.25006 5.42024 2.25098C3.68783 2.29351 2.29351 3.68783 2.25098 5.42024C2.25006 5.4578 2.25 5.50449 2.25 5.625V6C2.25 6.41421 1.91421 6.75 1.5 6.75C1.08579 6.75 0.75 6.41421 0.75 6V5.625L0.75 5.6131C0.749998 5.50841 0.749997 5.44184 0.751431 5.38343C0.813588 2.85144 2.85144 0.813588 5.38343 0.751431C5.44184 0.749997 5.50841 0.749998 5.6131 0.75ZM12.5798 2.25098C12.5422 2.25006 12.4955 2.25 12.375 2.25H12C11.5858 2.25 11.25 1.91421 11.25 1.5C11.25 1.08579 11.5858 0.75 12 0.75H12.375L12.3869 0.75C12.4916 0.749998 12.5582 0.749997 12.6166 0.751431C15.1486 0.813588 17.1864 2.85144 17.2486 5.38343C17.25 5.44183 17.25 5.50838 17.25 5.61305V5.625V6C17.25 6.41421 16.9142 6.75 16.5 6.75C16.0858 6.75 15.75 6.41421 15.75 6V5.625C15.75 5.50449 15.7499 5.4578 15.749 5.42024C15.7065 3.68783 14.3122 2.29351 12.5798 2.25098ZM1.5 11.25C1.91421 11.25 2.25 11.5858 2.25 12V12.375C2.25 12.4955 2.25006 12.5422 2.25098 12.5798C2.29351 14.3122 3.68783 15.7065 5.42024 15.749C5.4578 15.7499 5.50449 15.75 5.625 15.75H6C6.41421 15.75 6.75 16.0858 6.75 16.5C6.75 16.9142 6.41421 17.25 6 17.25H5.625H5.61305C5.50838 17.25 5.44183 17.25 5.38343 17.2486C2.85144 17.1864 0.813588 15.1486 0.751431 12.6166C0.749997 12.5582 0.749998 12.4916 0.75 12.3869L0.75 12.375V12C0.75 11.5858 1.08579 11.25 1.5 11.25ZM16.5 11.25C16.9142 11.25 17.25 11.5858 17.25 12V12.375V12.387C17.25 12.4916 17.25 12.5582 17.2486 12.6166C17.1864 15.1486 15.1486 17.1864 12.6166 17.2486C12.5582 17.25 12.4916 17.25 12.387 17.25H12.375H12C11.5858 17.25 11.25 16.9142 11.25 16.5C11.25 16.0858 11.5858 15.75 12 15.75H12.375C12.4955 15.75 12.5422 15.7499 12.5798 15.749C14.3122 15.7065 15.7065 14.3122 15.749 12.5798C15.7499 12.5422 15.75 12.4955 15.75 12.375V12C15.75 11.5858 16.0858 11.25 16.5 11.25Z"
      fill={color}
    />
  </svg>
);
