import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import moment from 'moment';

import {startDownload} from '../lib/download';
import {
  DropdownCTResponseProps,
  DropdownResponseProps,
  ICompanyInfo,
  ISearchResults,
  ParamsApiAutocomplete,
  ParamsApiDelete,
  ParamsApiDownload,
  ParamsApiPostDownload,
  ParamsApiSave,
  ParamsApiSearchCT,
  ResponsePropsForAsset,
  ResponsePropsForClinical,
  SavedSearchesType,
  SavedSearchResult,
  SearchBodyParams,
  SearchCTBodyParams,
  SearchCTDataProps,
  SearchDataProps,
} from '../lib/models';
import {RootState} from '../lib/stores/store';

import {
  parseDropDownForAssetQuery,
  parseDropDownForClinicalQuery,
  parseSavedResult,
  parseSearchCTResultResponse,
  parseSearchResultResponse,
} from './api_parsers';

interface ResponseApiStatus {
  status: string;
}

export const backendApi = createApi({
  reducerPath: 'backendApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_GATEWAY_URI}${process.env.REACT_APP_API_GATEWAY_VERSION}`,
    headers: {
      Accept: 'text/csv, application/json',
    },
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).user.accessToken;
      const idToken = (getState() as RootState).user.idToken;
      if (token !== '') {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('oidc-data', `${idToken}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Saved', 'Search'],
  endpoints: builder => ({
    getApiStatus: builder.query<ResponseApiStatus, void>({
      query: () => `health`,
    }),
    getApiAutocomplete: builder.query<string[], ParamsApiAutocomplete>({
      query: ({q, field_name, limit, ref}) => {
        return `${ref}/autocomplete?q=${q}&field_name=${field_name}&limit=${limit}`;
      },
    }),
    getApiDropdownForAsset: builder.query<DropdownResponseProps, void>({
      query: () => 'asset/static_fields',
      transformResponse: (response: ResponsePropsForAsset) => {
        return parseDropDownForAssetQuery(response);
      },
    }),
    getApiDropdownForClinical: builder.query<DropdownCTResponseProps, void>({
      query: () => 'ct/static_fields',
      transformResponse: (response: ResponsePropsForClinical) => {
        return parseDropDownForClinicalQuery(response);
      },
    }),
    searchResult: builder.mutation<ISearchResults, SearchBodyParams>({
      query: body => ({
        url: 'asset/search?limit=500',
        method: 'POST',
        body,
      }),
      transformResponse: (response: SearchDataProps) => {
        return parseSearchResultResponse(response);
      },
    }),
    searchCTResult: builder.mutation<SearchCTDataProps, ParamsApiSearchCT>({
      query: ({limit, skip, body}) => ({
        url: `ct/search?skip=${skip}&limit=${limit}`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: SearchCTDataProps) => {
        return parseSearchCTResultResponse(response);
      },
    }),
    getApiDownloadSearchResult: builder.mutation<Blob, ParamsApiDownload>({
      query: ({search_id}) => ({
        url: `asset/download?search_id=${search_id}`,
        method: 'GET',
        responseType: 'blob',
        responseHandler: async response => {
          const blob = await response.blob();
          const date = moment(moment.now()).format('DD/MM/YYYY');
          startDownload(blob, `HALEX_asset_searches__ref:${search_id}_${date}.xlsx`);
        },
      }),
    }),
    getApiDownloadPostSearchResult: builder.mutation<Blob, ParamsApiPostDownload>({
      query: ({search_id, body}) => ({
        url: `asset/download?search_id=${search_id}`,
        method: 'POST',
        body,
        responseHandler: async response => {
          const blob = await response.blob();
          const date = moment(moment.now()).format('DD/MM/YYYY');
          startDownload(blob, `HALEX_asset_searches__ref:${search_id}_${date}.xlsx`);
        },
      }),
    }),
    addSavedSearch: builder.mutation<string[], ParamsApiSave>({
      query: ({search_id, name}) => ({
        url: `asset/search/save?search_id=${search_id}&name=${name}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Saved'],
    }),
    getSavedSearches: builder.query<SavedSearchesType, void>({
      query: () => 'user/searches',
      transformResponse: (response: SavedSearchResult) => {
        return parseSavedResult(response);
      },
      providesTags: ['Saved'],
    }),
    deleteSaved: builder.mutation<string, ParamsApiDelete>({
      query: ({id}) => ({
        url: `asset/search?saved_search_id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Saved'],
    }),
    addSavedCT: builder.mutation<string[], ParamsApiSave>({
      query: ({search_id, name}) => ({
        url: `ct/search/save?search_id=${search_id}&name=${name}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Saved'],
    }),
    deleteSavedCT: builder.mutation<string, ParamsApiDelete>({
      query: ({id}) => ({
        url: `ct/search?saved_search_id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Saved'],
    }),
    getFiltersSaved: builder.query<SearchBodyParams, string | undefined>({
      query: id => `asset/search/payload?search_id=${id}`,
    }),
    getFiltersClinicalSaved: builder.query<SearchCTBodyParams, string | undefined>({
      query: id => `ct/search/payload?search_id=${id}`,
    }),
    getCompanyInfo: builder.query<ICompanyInfo, string>({
      query: name => `company?company_id=${encodeURIComponent(name)}`,
    }),
  }),
});

export const {
  useGetApiStatusQuery,
  useGetSavedSearchesQuery,
  useGetApiAutocompleteQuery,
  useGetApiDropdownForAssetQuery,
  useGetApiDropdownForClinicalQuery,
  useSearchResultMutation,
  useSearchCTResultMutation,
  useGetApiDownloadSearchResultMutation,
  useGetApiDownloadPostSearchResultMutation,
  useAddSavedSearchMutation,
  useDeleteSavedMutation,
  useAddSavedCTMutation,
  useDeleteSavedCTMutation,
  useLazyGetFiltersSavedQuery,
  useLazyGetFiltersClinicalSavedQuery,
  useLazyGetCompanyInfoQuery,
} = backendApi;
