import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface UserState {
  accessToken: string;
  idToken: string;
  error?: string;
}

interface Token {
  accessToken: string;
  idToken: string;
}

const initialState: UserState = {
  accessToken: '',
  idToken: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<Token>) {
      state.accessToken = action.payload.accessToken;
      state.idToken = action.payload.idToken;
    },
    setUserloginError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});
export const {setToken, setUserloginError} = userSlice.actions;
export default userSlice.reducer;
