// Top navigation
export const ACTIVE_MENU_CLASSNAME = 'active-menu';
export const CLUSTERING_REFRESH_DELAY = 60 * 1000;

// Badge
export const DOT_SIZE = 8;

// Column
export const TABLE_COLUMNS_SEARCH_FILTER = ['Clinical features', 'Disgenet'];

export const NO_INFO_COLUMN = 'No info';

// Timeout for api
export const TIMEOUT_DELAY = 60 * 1000;

// clustering option
export const STEP_1 = 'Clustering Preparation';
export const STEP_2 = 'Clustering results';
export const STEP_3 = 'Clustering overlap';
