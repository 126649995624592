import {CustomSvg} from '../lib/react';

interface HouseProps {
  size: number;
}

export const Business: CustomSvg<HouseProps> = ({size, color, ...rest}) => (
  <svg width={size} height={size} {...rest} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.31942 4.38081C2.68887 5.19494 2.26602 6.17803 2.13034 7.25065H2.66659C3.0808 7.25065 3.41659 7.58644 3.41659 8.00065C3.41659 8.41486 3.0808 8.75065 2.66659 8.75065H2.13034C2.22378 9.48931 2.4534 10.1855 2.79326 10.8133L2.95774 10.6488C3.78658 9.81999 4.88883 9.32742 6.0525 9.25891C6.26256 8.70774 6.69987 8.26872 7.24992 8.0564V6.00065C7.24992 5.58644 7.5857 5.25065 7.99992 5.25065C8.41413 5.25065 8.74992 5.58644 8.74992 6.00065V8.0564C9.29996 8.26872 9.73728 8.70774 9.94734 9.25891C11.111 9.32742 12.2133 9.81999 13.0421 10.6488L13.2066 10.8133C13.5464 10.1855 13.7761 9.48931 13.8695 8.75065H13.3333C12.919 8.75065 12.5833 8.41486 12.5833 8.00065C12.5833 7.58644 12.919 7.25065 13.3333 7.25065H13.8695C13.7338 6.17805 13.311 5.19496 12.6804 4.38084L12.5302 4.53108C12.2372 4.82392 11.7623 4.82383 11.4695 4.53088C11.1767 4.23794 11.1767 3.76306 11.4697 3.47022L11.6198 3.32018C10.8057 2.68961 9.82255 2.26675 8.74992 2.13107V2.66732C8.74992 3.08153 8.41413 3.41732 7.99992 3.41732C7.5857 3.41732 7.24992 3.08153 7.24992 2.66732V2.13107C6.1773 2.26675 5.1942 2.6896 4.38008 3.32015L4.53025 3.47032C4.82314 3.76321 4.82314 4.23809 4.53025 4.53098C4.23736 4.82387 3.76248 4.82387 3.46959 4.53098L3.31942 4.38081ZM4.0184 11.7095C4.56763 11.1603 5.29191 10.8263 6.06011 10.7621C6.36415 11.536 7.11805 12.084 7.99992 12.084C8.88179 12.084 9.63568 11.536 9.93973 10.7621C10.7079 10.8263 11.4322 11.1603 11.9814 11.7095L12.3178 12.0458C11.2382 13.1977 9.70312 13.9173 7.99992 13.9173C6.29672 13.9173 4.76161 13.1977 3.68208 12.0458L4.0184 11.7095ZM15.4166 8.00065C15.4166 3.90454 12.096 0.583984 7.99992 0.583984C3.90381 0.583984 0.583252 3.90454 0.583252 8.00065C0.583252 12.0968 3.90381 15.4173 7.99992 15.4173C12.096 15.4173 15.4166 12.0968 15.4166 8.00065ZM7.99992 9.41732C7.67775 9.41732 7.41658 9.67848 7.41658 10.0007C7.41658 10.3228 7.67775 10.584 7.99992 10.584C8.32208 10.584 8.58325 10.3228 8.58325 10.0007C8.58325 9.67848 8.32208 9.41732 7.99992 9.41732Z"
      fill="currentColor"
    />
  </svg>
);
