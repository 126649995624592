import {CustomSvg} from '../lib/react';

interface BurgerProps {
  size: number;
  color?: string;
}

export const Burger: CustomSvg<BurgerProps> = ({size, color, ...rest}) => (
  <svg
    width={size}
    height={size}
    {...(color === undefined ? {} : {fill: color})}
    {...rest}
    viewBox="0 0 27 24"
  >
    <rect y="0.5" width="27" height="3" rx="1.5" />
    <rect y="10.5" width="27" height="3" rx="1.5" />
    <rect y="20.5" width="27" height="3" rx="1.5" />
  </svg>
);
