import {BLACK, WHITE} from '@devipsendigital/react-ui-kit';
import styled from 'styled-components';

import packageInfo from '../../package.json';

export const Footer: React.FC = () => (
  <Wrapper>
    <Version>{packageInfo.version}</Version>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Version = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${({theme}) => (theme.name === 'light' ? BLACK : WHITE)};
`;
