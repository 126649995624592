import {FC, Fragment, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {createGlobalStyle, css} from 'styled-components';

import {HtmlProps} from '../../lib/react';
import {hideModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';

import {CompanyModal} from './CompanyModal';
import {DeletePhaseInfoModal} from './DeletePhaseInfoModal';
import {DisplayStageInfoModal} from './DisplayStageInfoModal';
import {InfoModal} from './InfoModal';
import {SaveSearchesModal} from './SaveSearchesModal';

interface ModalTypes {
  prop?: HtmlProps<HTMLDivElement>;
}

export const Modal: FC<ModalTypes> = props => {
  const {prop = {}} = props;
  const {show, modalType, param} = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();
  const handleHideModal = useCallback(() => {
    if (show === true) {
      dispatch(hideModal());
    }
  }, [dispatch, show]);

  const getChildren = () => {
    if (modalType === 'saved-search') {
      if (param !== undefined) {
        return <SaveSearchesModal param={param} />;
      }
    }
    if (modalType === 'display-phase-info') {
      return <DisplayStageInfoModal />;
    }
    if (modalType === 'delete-phase-info') {
      if (param !== undefined) {
        return <DeletePhaseInfoModal param={param.body as string} />;
      }
    }
    if (modalType === 'information') {
      if (param !== undefined) {
        return <InfoModal param={param}></InfoModal>;
      }
    }
    if (modalType === 'display-company') {
      if (param !== undefined) {
        return <CompanyModal></CompanyModal>;
      }
    }
    return <></>;
  };

  return (
    <Wrapper shown={show}>
      <Backdrop onClick={handleHideModal} shown={show} />
      <Dialog shown={show} {...prop}>
        {getChildren()}
      </Dialog>
      {show ? <NoScroll /> : <Fragment />}
    </Wrapper>
  );
};

const NoScroll = createGlobalStyle`${css`
  html,
  body {
    overflow-y: hidden;
  }
  #root {
    pointer-events: none;
  }
  @media print {
    #root {
      display: none;
    }
  }
`}`;

const Wrapper = styled.div<{shown: boolean}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: ${({shown}) => (shown ? 'auto' : 'hidden')};
  pointer-events: ${({shown}) => (shown ? 'all' : 'none')};
  @media print {
    position: initial;
    top: initial;
    right: initial;
    bottom: initial;
    left: initial;
  }
`;

const Backdrop = styled.div<{shown: boolean}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 300ms ease;
  background-color: ${({shown}) => (shown ? '#000000bb' : '#00000000')};
  @media print {
    display: none;
  }
`;

const Dialog = styled.div<{shown: boolean}>`
  position: relative;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({theme}) => theme.colors.headerBackground};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #c6c9d2;
  border-radius: 16px;
  box-sizing: border-box;
  margin-bottom: 150px;

  transition: all 300ms ease;
  transition-property: margin-top, opacity;
  opacity: ${({shown}) => (shown ? 1 : 0)};
  margin-top: ${({shown}) => (shown ? 150 : 80)}px;

  min-width: 406px;
  max-width: 550px;

  @media print {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    width: 100% !important;
    height: auto !important;
  }
`;
