import React, {Fragment} from 'react';
import styled from 'styled-components';

import {DOT_SIZE} from '../../lib/constants';

interface BadgeProps {
  children: React.ReactNode;
  visible: boolean;
}

export const Badge: React.FC<BadgeProps> = ({children, visible}) => (
  <Wrapper>
    {children}
    {visible ? <Dot /> : <Fragment />}
  </Wrapper>
);

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
`;

const Dot = styled.span`
  display: flex;
  position: absolute;
  padding: '0 6px';
  right: 0px;
  top: 0px;
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  min-width: ${DOT_SIZE / 2};
  border-radius: 4px;
`;
