import styled, {DefaultTheme} from 'styled-components';
import {Button, P2, Spacing, Text, WHITE} from '@devipsendigital/react-ui-kit';
import {ReactNode} from 'react';
import {useSelector} from 'react-redux';

import {CustomDiv} from '../../lib/react';
import {DARK_GREY_1, DARK_GREY_2, DARK_GREY_3} from '../../colors';
import {Pen} from '../../svg/pen';
import {TextFieldCT} from '../core/TextFieldCT';
import {Tick} from '../../svg/tick';
import {RootState} from '../../lib/stores/store';

interface StageListOption {
  title: string;
  date?: string;
  subtitles?: string;
  handleEditEntryClick: any;
  handleValidateEntryClick: any;
  isInterventionClicked?: boolean;
  isCompanyNameClicked?: boolean;
  isConditionClicked?: boolean;
  reference: string;
  phaseInfo: string;
  handleEditChange: any;
}

interface ThemeProps {
  theme: DefaultTheme;
  children: ReactNode;
  name: string;
}

export const StageEditInfo: CustomDiv<StageListOption> = props => {
  const {
    title,
    subtitles,
    handleEditEntryClick,
    handleEditChange,
    handleValidateEntryClick,
    isInterventionClicked,
    isCompanyNameClicked,
    isConditionClicked,
    reference,
    phaseInfo,
  } = props;

  const theme = useSelector((state: RootState) => state.theme.theme as ThemeProps);

  return (
    <Wrapper>
      <TextBlock>
        <Text color="light-grey" size="small" weight="light">
          {title}
        </Text>
        {!isInterventionClicked && !isCompanyNameClicked && !isConditionClicked ? (
          <P2WordBreak color={theme.name === 'light' ? DARK_GREY_1 : DARK_GREY_2}>
            {subtitles}
          </P2WordBreak>
        ) : (
          <TextFieldCT
            color={theme.name === 'light' ? DARK_GREY_1 : DARK_GREY_2}
            value={phaseInfo}
            onChange={handleEditChange}
          />
        )}
      </TextBlock>
      {!isInterventionClicked && !isCompanyNameClicked && !isConditionClicked ? (
        <PenSvgContainer id={reference} onClick={handleEditEntryClick}>
          <Pen color={DARK_GREY_3} size={25} />
        </PenSvgContainer>
      ) : (
        <ButtonBlock>
          <Button
            id={reference}
            typeButton={'primary'}
            size={'medium'}
            children={<Tick color={WHITE} size={50} />}
            width={40}
            height={40}
            onClick={handleValidateEntryClick}
          ></Button>
          <Spacing width={5} />
          <Button
            id={reference}
            typeButton={'primary'}
            size={'medium'}
            children={'X'}
            width={40}
            height={40}
            color={DARK_GREY_3}
            hoverColor={DARK_GREY_3}
            onClick={handleEditEntryClick}
          ></Button>
        </ButtonBlock>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 400px;
`;

const ButtonBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-right: 10px;
  color: ${({theme}) => theme.colors.textColor};
`;

const PenSvgContainer = styled.div`
  cursor: pointer;
`;

const P2WordBreak = styled(P2)`
  word-break: break-all;
`;
