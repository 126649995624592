import {DARK_THEME} from '@devipsendigital/react-ui-kit';
import {createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle<{theme: typeof DARK_THEME}>`
  html,
  body {
    font-family: 'Roboto', sans-serif;
    background-color: ${({theme}) => (theme as {colors: {background: string}}).colors.background};
  }
`;
