import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {Button, LIGHT_GREY_1, Text} from '@devipsendigital/react-ui-kit';

import {CustomDiv} from '../../lib/react';
import {Spacing} from '../core/Spacing';
import {Subtitle} from '../core/Typography';

interface CardListOption {
  url: string;
  button: string;
  title: string;
  text: string;
  icon?: JSX.Element;
}

export const Card: CustomDiv<CardListOption> = props => {
  const {url, button, title, text} = props;
  return (
    <Wrapper>
      <Subtitle>{title}</Subtitle>
      <Spacing height={17} />
      <TextBlock>
        <Text color="grey" size="normal" weight="light">
          {text}
        </Text>
      </TextBlock>
      <Spacing height={30} />
      <ButtonBlock>
        <NavLink to={url}>
          <Button size="medium" typeButton="primary">
            <Text size="normal" weight="bold" color="white">
              {button}
            </Text>
          </Button>
        </NavLink>
      </ButtonBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 302px;
  height: 293px;
  flex-direction: column;
  background: ${({theme}) => theme.colors.headerBackground};
  padding: 30px 30px 0 30px;
  margin-left: 30px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px ${({theme}) => (theme.name === 'light' ? LIGHT_GREY_1 : 'none')};
`;

const TextBlock = styled.div`
  color: ${({theme}) => theme.colors.textColor};
  width: 242px;
  height: 80px;
`;

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
