import styled from 'styled-components';
import {GREY_1, WHITE} from '@devipsendigital/react-ui-kit';

import {BLUE_LIGHT_HALEX, DARK_GREY_1, DARK_GREY_6} from '../../colors';
import {CustomDiv} from '../../lib/react';
import {Defined, Full} from '../../lib/type_utils';

export const Subtitle = styled.div`
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
  color: ${BLUE_LIGHT_HALEX};
`;

export interface TextProps {
  typeText?: 'h1' | 'h2' | 'p1' | 'p2' | 'p3';
  size?: 'xxsmall' | 'xsmall' | 'small' | 'normal' | 'large' | 'xlarge' | 'xxlarge'; // default to normal
  color?: 'black' | 'white' | 'dark-grey' | 'dark-grey-6'; // default to black
  weight?: 'light' | 'normal' | 'bold' | 'bolder'; // default to normal
  align?: 'left' | 'right' | 'center'; // default to left
  children?: React.ReactNode;
}

const Sizes: Record<Defined<TextProps['size']>, number> = {
  xxsmall: 10,
  xsmall: 13,
  small: 15,
  normal: 17,
  large: 20,
  xlarge: 30,
  xxlarge: 50,
};
const Colors: Record<Defined<TextProps['color']>, string> = {
  black: GREY_1,
  white: WHITE,
  'dark-grey': DARK_GREY_1,
  'dark-grey-6': DARK_GREY_6,
};

const Weights: Record<Defined<TextProps['weight']>, number> = {
  light: 300,
  normal: 400,
  bold: 500,
  bolder: 700,
};

export const Text: CustomDiv<TextProps> = props => {
  const {
    typeText = 'h1',
    size = 'normal',
    color = 'black',
    weight = 'normal',
    align = 'left',
    children,
    style = {},
    ...rest
  } = props;

  return (
    <TextDiv
      typeText={typeText}
      size={size}
      color={color}
      weight={weight}
      align={align}
      style={{
        ...style,
      }}
      {...rest}
    >
      {children}
    </TextDiv>
  );
};

const TextDiv = styled.div<Omit<Full<TextProps>, 'children'>>`
  line-height: ${p => Sizes[p.size] * 1.25}px;
  font-size: ${p => Sizes[p.size]}px;
  color: ${p => Colors[p.color]};
  font-weight: ${p => Weights[p.weight]};
  text-align: ${p => p.align};
`;
