import {Button, P1, Spacing, Spinner, WHITE, getIcon} from '@devipsendigital/react-ui-kit';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {
  useGetApiDropdownForClinicalQuery,
  useLazyGetFiltersClinicalSavedQuery,
  useSearchCTResultMutation,
} from '../../api/apiGateway';
import {DARK_GREY_2} from '../../colors';
import {
  setAge,
  setConditions,
  setData,
  setFilters,
  setFunderType,
  setGender,
  setHealthyVolunteers,
  setModalInfo,
  setPhases,
  setSearchId,
  setStatus,
  setStudy,
} from '../../lib/stores/clinical';
import {ExtraEnum, showModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';
import {DropdownAutocomplete, FeatureName, FieldAutocomplete} from '../core/DropdownAutocomplete';
import {DropdownSelect, FieldNameClinicals} from '../core/DropdownSelect';
import {savedSearches} from '../modals/SaveSearchesModal';
import {WrapperSpinner} from '../stylesGeneric';

interface ClinicalFiltersParams {
  clinicalId?: string;
}

export const ClinicalFilters: React.FC<ClinicalFiltersParams> = ({clinicalId}) => {
  const [rawOpen, setRawOpen] = useState(true);

  const filterSaved = useSelector((state: RootState) => state.clinical.filters);
  const dispatch = useDispatch();

  const clinicalResults = useSelector((state: RootState) => state.clinical.data);

  const {skip} = clinicalResults;

  const {
    all_conditions,
    overall_status,
    processed_phase,
    gender,
    age_group,
    published_results,
    lead_sponsor_funding,
    healthy_volunteers,
  } = filterSaved;

  const handleDiseaseChange = useCallback(
    (disease: string[]) => {
      dispatch(setConditions(disease));
    },
    [dispatch]
  );

  const [postResultForCTResults, {isLoading}] = useSearchCTResultMutation();

  const handleStatusChange = useCallback(
    (status: string[]) => {
      dispatch(setStatus(status));
    },
    [dispatch]
  );

  const handlePhasesChange = useCallback(
    (phases: string[]) => {
      dispatch(setPhases(phases));
    },
    [dispatch]
  );

  const handleGenderChange = useCallback(
    (gender: string[]) => {
      dispatch(setGender(gender));
    },
    [dispatch]
  );

  const handleAgeChange = useCallback(
    (age: string[]) => {
      dispatch(setAge(age));
    },
    [dispatch]
  );

  const handleStudyChange = useCallback(
    (study: string[]) => {
      dispatch(setStudy(study));
    },
    [dispatch]
  );

  const handleFunderTypesChange = useCallback(
    (funderTypes: string[]) => {
      dispatch(setFunderType(funderTypes));
    },
    [dispatch]
  );
  const handleHealthyVolunteers = useCallback(
    (healthCare: string[]) => {
      dispatch(setHealthyVolunteers(healthCare));
    },
    [dispatch]
  );
  const {data: allClinicals} = useGetApiDropdownForClinicalQuery();
  const handleRawOpened = useCallback(() => setRawOpen(!rawOpen), [rawOpen]);

  const [hasFilterValueSelected, setHasFilterValueSelected] = useState(false);

  const [getFilters] = useLazyGetFiltersClinicalSavedQuery();

  const displayResult = useCallback(async () => {
    const selectAllToRemove = filterSaved.overall_status.findIndex(val => val === '- Select All -');
    const filters = {
      ...filterSaved,
      overall_status: selectAllToRemove !== -1 ? [] : overall_status,
    };
    postResultForCTResults({limit: 10, skip: 0, body: filters})
      .unwrap()
      .then(payload => {
        dispatch(setData({...payload, skip: skip + 10}));
        dispatch(setSearchId(payload.search_id));
        dispatch(setModalInfo(false));
      });
  }, [filterSaved, overall_status, postResultForCTResults, dispatch, skip]);

  useEffect(() => {
    if (clinicalId !== undefined) {
      getFilters(clinicalId)
        .unwrap()
        .then(payload => {
          dispatch(setFilters(payload));
          dispatch(setModalInfo(false));
          postResultForCTResults({limit: 10, skip: 0, body: payload})
            .unwrap()
            .then(payload => {
              dispatch(setData({...payload, skip: skip + 10}));
            });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilters = () => {
    dispatch(
      showModal({
        modalType: 'saved-search',
        param: {
          type: savedSearches.SAVED_CT,
          body: 'Are you sure you want to reset your search?',
          extra: ExtraEnum.RESET_FILTERS,
        },
      })
    );
  };

  const hasFiltersValue = useCallback(() => {
    const hasValue = Object.values(filterSaved).find((val: string[]) => {
      return val.length > 0;
    });
    return hasValue === undefined ? false : true;
  }, [filterSaved]);

  useEffect(() => {
    setHasFilterValueSelected(hasFiltersValue());
  }, [filterSaved, hasFiltersValue]);

  const checkMandatoryFieldsFilled = () => {
    return all_conditions.length > 0 && overall_status.length > 0;
  };

  useEffect(() => {
    if (hasFiltersValue()) {
      window.addEventListener('beforeunload', alertUser);
      return () => {
        window.removeEventListener('beforeunload', alertUser);
      };
    }
    return;
  }, [hasFiltersValue]);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
  };

  return (
    <Wrapper>
      <WrapperFilters isExpanded={rawOpen}>
        <WrapperFiltersContainer rawOpen={rawOpen}>
          <DropdownAutocomplete
            selected={all_conditions}
            placeholder={'Condition or Disease *'}
            field={FieldAutocomplete.CONDITIONS}
            onChange={handleDiseaseChange}
            feature={FeatureName.CLINICALS}
          />
          <DropdownSelect
            selected={overall_status}
            onChange={handleStatusChange}
            data={allClinicals?.[FieldNameClinicals.STATUS] ?? []}
            placeholder="Status *"
          />
          <DropdownSelect
            selected={processed_phase}
            onChange={handlePhasesChange}
            data={allClinicals?.[FieldNameClinicals.PHASES] ?? []}
            placeholder="Phase"
          />

          {!rawOpen && (
            <DropdownSelect
              selected={gender}
              onChange={handleGenderChange}
              data={allClinicals?.[FieldNameClinicals.GENDER] ?? []}
              placeholder="Gender"
              isSingleValue={true}
            />
          )}

          {rawOpen && (
            <WrapperColumn>
              <Button
                onClick={displayResult}
                typeButton={'primary'}
                size={'medium'}
                width={270}
                disabled={!checkMandatoryFieldsFilled()}
              >
                {isLoading ? (
                  <WrapperSpinner>
                    <Spinner spinnerSize={30} spinnerThickness={3} color={WHITE} />
                  </WrapperSpinner>
                ) : (
                  <>Search</>
                )}
              </Button>
              {hasFilterValueSelected && (
                <WrapperReset onClick={resetFilters}>Reset All</WrapperReset>
              )}
            </WrapperColumn>
          )}
        </WrapperFiltersContainer>
        {!rawOpen && (
          <>
            <WrapperFiltersContainer rawOpen={rawOpen}>
              <DropdownSelect
                selected={age_group}
                onChange={handleAgeChange}
                data={allClinicals?.[FieldNameClinicals.AGE_GROUP] ?? []}
                placeholder="Age group"
              />
              <DropdownSelect
                selected={published_results}
                onChange={handleStudyChange}
                data={allClinicals?.[FieldNameClinicals.STUDY] ?? []}
                placeholder="Study has results"
                isSingleValue={true}
              />
              <DropdownSelect
                selected={lead_sponsor_funding}
                onChange={handleFunderTypesChange}
                data={allClinicals?.[FieldNameClinicals.FUNDER] ?? []}
                placeholder="Funder Type"
              />
              <DropdownSelect
                selected={healthy_volunteers}
                onChange={handleHealthyVolunteers}
                data={allClinicals?.[FieldNameClinicals.HEALTHY] ?? []}
                placeholder="Accepts Healthy Volunteers"
              />
              <WrapperColumn>
                <Button
                  onClick={displayResult}
                  typeButton={'primary'}
                  size={'medium'}
                  width={270}
                  disabled={!checkMandatoryFieldsFilled()}
                >
                  {isLoading ? (
                    <WrapperSpinner>
                      <Spinner spinnerSize={30} spinnerThickness={3} color={WHITE} />
                    </WrapperSpinner>
                  ) : (
                    <>Search</>
                  )}
                </Button>
                {hasFilterValueSelected && (
                  <WrapperReset onClick={resetFilters}>Reset All</WrapperReset>
                )}
              </WrapperColumn>
            </WrapperFiltersContainer>
          </>
        )}
      </WrapperFilters>
      <DisplayFilters onClick={handleRawOpened}>
        <P1>{rawOpen ? 'More filters' : 'Less filters'}</P1>
        {rawOpen ? (
          <ArrowDown onClick={handleRawOpened}>{getIcon('expandableArrowDown', 50)}</ArrowDown>
        ) : (
          <ArrowUp onClick={handleRawOpened}>{getIcon('expandableArrowUp', 50)}</ArrowUp>
        )}
      </DisplayFilters>
      <Spacing height={10} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media print {
    display: none;
  }
`;

const WrapperFilters = styled.div<{isExpanded: boolean}>`
  display: flex;
  flex-direction: column;
  height: ${({isExpanded}) => (isExpanded ? '130px' : '220px')};
  background: ${({theme}) => theme.inputSelect.colors.background};
  border: 1px solid #c6c9d2;
  border-radius: 16px;
  justify-content: space-around;
`;

const WrapperFiltersContainer = styled.div<{rawOpen: boolean}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  gap: 2rem;
`;

const DisplayFilters = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  width: 170px;
  height: '40px';
  left: calc(50% - 164px / 2);
  top: -20px;
  background: ${({theme}) => theme.inputSelect.colors.background};
  border-radius: 30px;
  cursor: pointer;
`;

const ArrowDown = styled.div`
  background: ${DARK_GREY_2};
  border-radius: 27px;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.switch.toggle.colors.normal};
  }
`;

const ArrowUp = styled.div`
  background: ${({theme}) => theme.switch.toggle.colors.normal};
  border-radius: 27px;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.switch.toggle.colors.normal};
  }
`;

const WrapperReset = styled.div`
  color: ${({theme}) => theme.colors.textColor};
  box-sizing: border-box;
  font-size: 15px;
  cursor: pointer;
  text-decoration: underline;
`;

const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  jusify-content: center;
  align-items: center;
  gap: 20px;
`;
