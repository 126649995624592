import {useCallback, useEffect} from 'react';

export function useClickOutside<T extends HTMLElement>(
  refs: React.RefObject<T> | React.RefObject<T>[],
  callback: (e: MouseEvent) => void
): void {
  const handleGlobalMouseClick = useCallback(
    (e: MouseEvent) => {
      const refsToCheck = (Array.isArray(refs) ? refs : [refs]).map(ref => ref.current);
      let currentTarget = e.target as T | null;

      while (currentTarget !== null && !refsToCheck.includes(currentTarget)) {
        currentTarget = currentTarget.parentElement as T | null;
      }
      if (currentTarget === null) {
        callback(e);
      }
    },
    [callback, refs]
  );

  useEffect(() => {
    window.addEventListener('click', handleGlobalMouseClick);
    return () => window.removeEventListener('click', handleGlobalMouseClick);
  }, [handleGlobalMouseClick]);
}
