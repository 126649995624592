import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {SearchBodyParams, IDataSearchResult, ISearchResults} from '../models';

export interface SearchState {
  searchId: string;
  filters: SearchBodyParams;
  data: IDataSearchResult[];
  total: number;
}

const initialState: SearchState = {
  searchId: '',
  filters: {
    all_drugs: [],
    active_companies: [],
    active_companies_geography: [],
    indication: [],
    therapy_area: [],
    target_based_actions: [],
    drug_category: [],
    highest_status: [],
    highest_status_region: [],
    product_geography: [],
    status: [],
    regulatory_designations: [],
  },
  data: [],
  total: 0,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchId(state, action: PayloadAction<string>) {
      state.searchId = action.payload;
    },
    setFilters(state, action: PayloadAction<SearchBodyParams>) {
      state.filters = action.payload;
    },
    setData(state, action: PayloadAction<ISearchResults>) {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.searchId = action.payload.search_id;
    },
    resetAssetFilters: () => initialState,
    setDrugs(state, action: PayloadAction<string[]>) {
      state.filters.all_drugs = action.payload;
    },
    setActiveCompaines(state, action: PayloadAction<string[]>) {
      state.filters.active_companies = action.payload;
    },
    setActiveCompainesGeo(state, action: PayloadAction<string[]>) {
      state.filters.active_companies_geography = action.payload;
    },
    setIndication(state, action: PayloadAction<string[]>) {
      state.filters.indication = action.payload;
    },
    setTherapyArea(state, action: PayloadAction<string[]>) {
      state.filters.therapy_area = action.payload;
    },
    setTarget(state, action: PayloadAction<string[]>) {
      state.filters.target_based_actions = action.payload;
    },
    setDrugCategory(state, action: PayloadAction<string[]>) {
      state.filters.drug_category = action.payload;
    },
    setHighestStatus(state, action: PayloadAction<string[]>) {
      state.filters.highest_status = action.payload;
    },
    setHighestStatusRegion(state, action: PayloadAction<string[]>) {
      state.filters.highest_status_region = action.payload;
    },
    setProductGeo(state, action: PayloadAction<string[]>) {
      state.filters.product_geography = action.payload;
    },
    setStatus(state, action: PayloadAction<string[]>) {
      state.filters.status = action.payload;
    },
    setRegulatoryDesignations(state, action: PayloadAction<string[]>) {
      state.filters.regulatory_designations = action.payload;
    },
  },
});
export const {
  setSearchId,
  setFilters,
  setData,
  resetAssetFilters,
  setDrugs,
  setActiveCompaines,
  setActiveCompainesGeo,
  setIndication,
  setTherapyArea,
  setTarget,
  setDrugCategory,
  setHighestStatus,
  setHighestStatusRegion,
  setProductGeo,
  setStatus,
  setRegulatoryDesignations,
} = searchSlice.actions;
export default searchSlice.reducer;
