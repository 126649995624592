import {CustomSvg} from '../lib/react';

interface CheckProps {
  size: number;
  color?: string;
}

export const Check: CustomSvg<CheckProps> = ({size, color}) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
    <rect x="1" y="1" width="18" height="18" rx="9" stroke={color} strokeWidth="2" />
  </svg>
);
