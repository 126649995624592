import {FC, Fragment, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';

import {hideModal} from '../../lib/stores/modal';

import {DeletePhaseForm} from './DeleteStageForm';
import {ModalContent, ModalTitle} from './ModalFragments';

interface DeletePhaseInfoModalProps {
  param: string;
}

export const DeletePhaseInfoModal: FC<DeletePhaseInfoModalProps> = ({param}) => {
  const [, setSavingState] = useState<'saving' | 'success' | Error>('saving');
  const dispatch = useDispatch();

  useEffect(() => setSavingState('saving'), []);

  const handleSavingFailure = useCallback((err: Error) => setSavingState(err), []);
  const handleSubmit = useCallback(() => {
    toast.success(
      `The clinical trial info ref: ${param} has been successfully removed from the view`
    );
    dispatch(hideModal());
  }, [dispatch, param]);

  return (
    <Fragment>
      <ModalTitle>{`Remove clinical trial with ref: ${param} from view?`}</ModalTitle>
      <ModalContent>
        <DeletePhaseForm onSuccess={handleSubmit} onFailure={handleSavingFailure} param={param} />
      </ModalContent>
    </Fragment>
  );
};
