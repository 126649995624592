import {WHITE} from '@devipsendigital/react-ui-kit';

import {CustomSvg} from '../lib/react';

interface BinProps {
  size: number;
  color?: string;
}

export const Bin: CustomSvg<BinProps> = ({size, color, ...rest}) => (
  <svg
    width={size}
    height={size}
    {...(color === undefined ? {} : {fill: color})}
    {...rest}
    viewBox="0 0 17 24"
  >
    <path
      d="M2 6V17C2 18.6569 3.34315 20 5 20H11C12.6569 20 14 18.6569 14 17V6"
      stroke={WHITE}
      strokeWidth="2"
    />
    <line y1="5" x2="16" y2="5" stroke={WHITE} strokeWidth="2" />
    <line x1="6" y1="9" x2="6" y2="16" stroke={WHITE} strokeWidth="2" />
    <line x1="10" y1="9" x2="10" y2="16" stroke={WHITE} strokeWidth="2" />
    <path
      d="M11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4"
      stroke={WHITE}
      strokeWidth="2"
    />
  </svg>
);
