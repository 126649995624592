import {CustomSvg} from '../lib/react';

interface PinProps {
  size: number;
  color?: string;
}

export const Pin: CustomSvg<PinProps> = ({size, color, ...rest}) => (
  <svg
    width={size}
    height={size}
    {...(color === undefined ? {} : {fill: color})}
    {...rest}
    viewBox="0 0 25 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7.875C3 4.59461 5.60656 2.25 9 2.25C12.3934 2.25 15 4.59461 15 7.875C15 10.5745 13.8031 12.5435 12.4219 13.8511C11.7284 14.5076 10.9937 14.9915 10.3544 15.3084C9.69032 15.6375 9.20427 15.7499 9 15.7499C8.79573 15.7499 8.30968 15.6375 7.64558 15.3084C7.00629 14.9915 6.27156 14.5076 5.57813 13.8511C4.1969 12.5435 3 10.5745 3 7.875ZM9 0.75C4.89344 0.75 1.5 3.65539 1.5 7.875C1.5 11.0644 2.9281 13.4078 4.54687 14.9404C5.35344 15.704 6.21246 16.2722 6.97942 16.6523C7.72157 17.0202 8.45427 17.2499 9 17.2499C9.54573 17.2499 10.2784 17.0202 11.0206 16.6523C11.7875 16.2722 12.6466 15.704 13.4531 14.9404C15.0719 13.4078 16.5 11.0644 16.5 7.875C16.5 3.65539 13.1066 0.75 9 0.75ZM7.5 8.25C7.5 7.42157 8.17157 6.75 9 6.75C9.82843 6.75 10.5 7.42157 10.5 8.25C10.5 9.07843 9.82843 9.75 9 9.75C8.17157 9.75 7.5 9.07843 7.5 8.25ZM9 5.25C7.34315 5.25 6 6.59315 6 8.25C6 9.90685 7.34315 11.25 9 11.25C10.6569 11.25 12 9.90685 12 8.25C12 6.59315 10.6569 5.25 9 5.25Z"
      fill={color}
    />
  </svg>
);
