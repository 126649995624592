import {CustomSvg} from '../lib/react';

interface TimeProps {
  size: number;
}

export const Time: CustomSvg<TimeProps> = ({size, color, ...rest}) => (
  <svg width={size} height={size} {...rest} viewBox="0 0 16 16">
    <g clipPath="url(#clip0_3463_31548)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 0.583984C3.90381 0.583984 0.583252 3.90454 0.583252 8.00065C0.583252 12.0968 3.90381 15.4173 7.99992 15.4173C12.096 15.4173 15.4166 12.0968 15.4166 8.00065C15.4166 3.90454 12.096 0.583984 7.99992 0.583984ZM2.08325 8.00065C2.08325 4.73297 4.73223 2.08398 7.99992 2.08398C11.2676 2.08398 13.9166 4.73297 13.9166 8.00065C13.9166 11.2683 11.2676 13.9173 7.99992 13.9173C4.73223 13.9173 2.08325 11.2683 2.08325 8.00065ZM8.74992 5.33398C8.74992 4.91977 8.41413 4.58398 7.99992 4.58398C7.5857 4.58398 7.24992 4.91977 7.24992 5.33398V8.00065C7.24992 8.25142 7.37524 8.48559 7.58389 8.62469L9.58389 9.95802C9.92854 10.1878 10.3942 10.0947 10.624 9.75001C10.8537 9.40536 10.7606 8.93971 10.4159 8.70995L8.74992 7.59926V5.33398Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3463_31548">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
