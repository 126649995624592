import {Spinner} from '@devipsendigital/react-ui-kit';
import {FC, Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {useLazyGetCompanyInfoQuery} from '../../api/apiGateway';
import {BLUE_BUTTON_LIGHT, DARK_GREY_3} from '../../colors';
import {ICompanyInfo} from '../../lib/models';
import {hideModal} from '../../lib/stores/modal';
import {RootState} from '../../lib/stores/store';
import {Asset} from '../../svg/asset';
import {Atom} from '../../svg/atom';
import {Business} from '../../svg/business';
import {Calculator} from '../../svg/calculator';
import {Calendar} from '../../svg/calendar';
import {Contact} from '../../svg/contact';
import {Employees} from '../../svg/employees';
import {EnterpriseValue} from '../../svg/entrepriseValue';
import {ExitFromPhase} from '../../svg/exitFromPhase';
import {House} from '../../svg/house';
import {Location} from '../../svg/location';
import {Medical} from '../../svg/medical';
import {Time} from '../../svg/time';
import {World} from '../../svg/world';
import {Spacing} from '../core/Spacing';

import {ModalActions, ModalTitle, WrapperExit} from './ModalFragments';
import {Body} from './SaveSearchesForm';
import {TextBlock} from './TextBlock';

export const CompanyModal: FC = () => {
  const dispatch = useDispatch();
  const {param} = useSelector((state: RootState) => state.modal);

  const [getCompany] = useLazyGetCompanyInfoQuery();

  const [data, setData] = useState<ICompanyInfo>();

  const [isLoading, setIsLoading] = useState(false);

  const handleHideModal = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    if (param?.title !== undefined) {
      getCompany(param?.title)
        .unwrap()
        .then(payload => {
          setData(payload);
          setIsLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setIsLoading(false);
        });
    }
  }, [getCompany, param?.title]);

  const modalRef = useRef(null);

  return (
    <Fragment key={new Date().getTime()}>
      <ModalActions>
        <ModalTitle>{param?.title}</ModalTitle>
        <WrapperExit>
          <ExitFromPhase color={DARK_GREY_3} size={25} onClick={handleHideModal} />
        </WrapperExit>
      </ModalActions>
      <Spacing height={20} />
      {isLoading ? (
        <Spinner spinnerSize={30} spinnerThickness={5}></Spinner>
      ) : (
        <>
          <>
            {data?.PitchBookProfileLink !== undefined && (
              <WeapperButton>
                <ButtonPitchBook target="_blank" href={data.PitchBookProfileLink}>
                  Pitchbook
                </ButtonPitchBook>
              </WeapperButton>
            )}
          </>
          <Spacing height={30} />

          {data ? (
            <Wrapper ref={modalRef}>
              <WrapperLine>
                <TextBlock
                  title="Company Name"
                  value={data?.CompanyName ?? 'N/A'}
                  icon={<House size={16} />}
                ></TextBlock>
                <TextBlock
                  title="Website"
                  value={data?.Website ?? 'N/A'}
                  icon={<World size={16} />}
                  isLink={true}
                ></TextBlock>
                {/* <TextBlock
                  title="Entity Type"
                  value={data?.OwnershipStatus ?? 'N/A'}
                  icon={<House size={16} />}
                ></TextBlock> */}
                <TextBlock
                  title="Business status"
                  value={data?.BusinessStatus ?? 'N/A'}
                  icon={<Business size={16} />}
                ></TextBlock>
              </WrapperLine>
              <WrapperLine>
                <TextBlock
                  title="Ownership status"
                  value={data?.OwnershipStatus ?? 'N/A'}
                  icon={<Business size={16} />}
                ></TextBlock>
                <TextBlock
                  title="Year of Founding"
                  value={`${data?.YearFounded ?? 'N/A'}`}
                  icon={<Time size={16} />}
                ></TextBlock>
                <TextBlock
                  title="HQ location"
                  value={data?.HQLocation ?? 'N/A'}
                  icon={<Location size={16} />}
                ></TextBlock>
              </WrapperLine>
              <WrapperLine>
                <TextBlock
                  title="Universe"
                  value={data?.Universe ?? 'N/A'}
                  icon={<Atom size={16} />}
                ></TextBlock>
                <TextBlock
                  title="# Employees"
                  value={`${data?.Employees ?? 'N/A'}`}
                  icon={<Employees size={16} />}
                ></TextBlock>
                <TextBlock
                  title="Entreprise Value"
                  value={`${data?.EnterpriseValue ?? 'N/A'}`}
                  icon={<EnterpriseValue size={16} />}
                ></TextBlock>
              </WrapperLine>
              <WrapperLine>
                <TextBlock
                  title="Primary Contact"
                  value={`${data?.PrimaryContact}, ${data?.PrimaryContactTitle} - ${data?.PrimaryContactPhone} - ${data?.PrimaryContactEmail}`}
                  icon={<Contact size={16} />}
                ></TextBlock>
              </WrapperLine>
              <WrapperLine>
                <TextBlock
                  title="Therapeutic Area(s)"
                  value={
                    data?.TherapeuticAreas !== undefined ? data?.TherapeuticAreas.join(', ') : 'N/A'
                  }
                  icon={<Medical size={16} />}
                ></TextBlock>
              </WrapperLine>
              <WrapperLine>
                <TextBlock
                  title="# of Assets"
                  value={`${data?.NumAssets ?? 'N/A'}`}
                  icon={<Asset size={16} />}
                ></TextBlock>
                <TextBlock
                  title="Deals"
                  value={data?.DealSize ?? 'N/A'}
                  icon={<EnterpriseValue size={16} />}
                ></TextBlock>
                <TextBlock
                  title="Deal date"
                  value={`${data?.DealDate ?? 'N/A'}`}
                  icon={<Calendar size={16} />}
                ></TextBlock>
              </WrapperLine>
              <WrapperLine>
                {/* <TextBlock
                  title="Price per share"
                  value={`${data?.SharePrice ?? 'N/A'}`}
                  icon={<Sharing size={16} />}
                ></TextBlock> */}
                <TextBlock
                  title="Total Capital Raised"
                  value={data?.TotalRaised ?? 'N/A'}
                  icon={<EnterpriseValue size={16} />}
                ></TextBlock>
                <TextBlock
                  title="Company Financing Status"
                  value={data?.CompanyFinancingStatus ?? 'N/A'}
                  icon={<Calculator size={16} />}
                ></TextBlock>
                <TextBlock
                  title="Last financing date"
                  value={data?.LastFinancingDate ?? 'N/A'}
                  icon={<Calendar size={16} />}
                ></TextBlock>
              </WrapperLine>
              <WrapperLine>
                {/* <TextBlock
                  title="Market CAP"
                  value={`${data?.MarketCap}` ?? 'N/A'}
                  icon={<EnterpriseValue size={16} />}
                ></TextBlock> */}
                <TextBlock
                  title="Last financing size"
                  value={`${data?.LastFinancingSize ?? 'N/A'}`}
                  icon={<Asset size={16} />}
                ></TextBlock>
              </WrapperLine>
            </Wrapper>
          ) : (
            <Body>The information of this company is not available</Body>
          )}
        </>
      )}
    </Fragment>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const WrapperLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
`;

const ButtonPitchBook = styled.a`
  border: 2px solid ${BLUE_BUTTON_LIGHT};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;
  color: ${BLUE_BUTTON_LIGHT};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
`;

const WeapperButton = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;
