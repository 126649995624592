import {CustomSvg} from '../lib/react';

interface AssetProps {
  size: number;
}

export const Asset: CustomSvg<AssetProps> = ({size, color, ...rest}) => (
  <svg width={size} height={size} {...rest} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99992 2.08398C3.38909 2.08398 2.08325 3.38982 2.08325 5.00065V5.33398C2.08325 5.7482 1.74747 6.08398 1.33325 6.08398C0.919038 6.08398 0.583252 5.7482 0.583252 5.33398V5.00065C0.583252 2.56139 2.56066 0.583984 4.99992 0.583984H5.33325C5.74747 0.583984 6.08325 0.919771 6.08325 1.33398C6.08325 1.7482 5.74747 2.08398 5.33325 2.08398H4.99992ZM9.91659 1.33398C9.91659 0.919771 10.2524 0.583984 10.6666 0.583984H10.9999C13.4392 0.583984 15.4166 2.56139 15.4166 5.00065V5.33398C15.4166 5.7482 15.0808 6.08398 14.6666 6.08398C14.2524 6.08398 13.9166 5.7482 13.9166 5.33398V5.00065C13.9166 3.38982 12.6107 2.08398 10.9999 2.08398H10.6666C10.2524 2.08398 9.91659 1.7482 9.91659 1.33398ZM1.33325 9.91732C1.74747 9.91732 2.08325 10.2531 2.08325 10.6673V11.0007C2.08325 12.6115 3.38909 13.9173 4.99992 13.9173H5.33325C5.74747 13.9173 6.08325 14.2531 6.08325 14.6673C6.08325 15.0815 5.74747 15.4173 5.33325 15.4173H4.99992C2.56066 15.4173 0.583252 13.4399 0.583252 11.0007V10.6673C0.583252 10.2531 0.919038 9.91732 1.33325 9.91732ZM14.6666 9.91732C15.0808 9.91732 15.4166 10.2531 15.4166 10.6673V11.0007C15.4166 13.4399 13.4392 15.4173 10.9999 15.4173H10.6666C10.2524 15.4173 9.91659 15.0815 9.91659 14.6673C9.91659 14.2531 10.2524 13.9173 10.6666 13.9173H10.9999C12.6107 13.9173 13.9166 12.6115 13.9166 11.0007V10.6673C13.9166 10.2531 14.2524 9.91732 14.6666 9.91732Z"
      fill="currentColor"
    />
  </svg>
);
