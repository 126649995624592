import {BLACK, BORDER_RADIUS, LIGHT_GREY_1, Spacing, WHITE} from '@devipsendigital/react-ui-kit';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import {
  useDeleteSavedCTMutation,
  useDeleteSavedMutation,
  useGetSavedSearchesQuery,
} from '../../api/apiGateway';
import {BLUE_HALEX_MAIN, BLUE_LIGHT_HALEX} from '../../colors';
import {MergedSavedDataProps} from '../../lib/models';
import {Bin} from '../../svg/bin';

export const MySavedSearchesScreen: React.FC = () => {
  const [activeSearches, setActiveSearches] = useState(false);
  const [activeClinicals, setActiveClinicals] = useState(true);

  const [dataToDisplay, setDataToDisplay] = useState<MergedSavedDataProps[]>([]);

  const {data} = useGetSavedSearchesQuery();
  const navigate = useNavigate();

  const [deleteSearch] = useDeleteSavedMutation();
  const [deleteSearchCT] = useDeleteSavedCTMutation();

  const handleDelete = useCallback(
    async (id: string, name: string) => {
      if (activeSearches) {
        await deleteSearch({id});
      } else {
        await deleteSearchCT({id});
      }
      toast.success(`${name} has been deleted`);
    },
    [activeSearches, deleteSearch, deleteSearchCT]
  );

  const handleSearchQuerySelect = useCallback(
    (savedId: string) => {
      if (activeSearches) {
        navigate(`/assets/${savedId}`);
      } else {
        navigate(`/clinical-trials/${savedId}`);
      }
    },
    [activeSearches, navigate]
  );

  useEffect(() => {
    if (data !== undefined) {
      return activeClinicals ? setDataToDisplay(data.clinical) : setDataToDisplay(data.asset);
    } else {
      return setDataToDisplay([]);
    }
  }, [activeClinicals, data]);

  return (
    <Wrapper>
      <Spacing height={90} />
      <WrapperTab>
        <ButtonTab
          active={activeSearches}
          onClick={() => {
            setActiveSearches(true);
            setActiveClinicals(false);
          }}
        >
          <WrapperNumber active={activeSearches}>
            {data !== undefined ? data.asset.length : 0}
          </WrapperNumber>
          <Spacing width={10} />
          Saved Assets Searches
        </ButtonTab>
        <ButtonTab
          active={activeClinicals}
          onClick={() => {
            setActiveClinicals(true);
            setActiveSearches(false);
          }}
        >
          <WrapperNumber active={activeClinicals}>
            {data !== undefined ? data.clinical.length : 0}
          </WrapperNumber>
          <Spacing width={10} />
          Saved Clinical Trials Searches
        </ButtonTab>
      </WrapperTab>
      <Line></Line>
      <Spacing height={30} />
      <ContainerDataSaved>
        <HeaderSaved>
          <Column>Name</Column>
          <Column>Date</Column>
        </HeaderSaved>
        {dataToDisplay.map(row => {
          return (
            <WrapperData key={row.id}>
              <Name onClick={() => handleSearchQuerySelect(row.id)}>{row.name}</Name>
              <DateDisplay onClick={() => handleSearchQuerySelect(row.id)}>{row.date}</DateDisplay>

              <BinContainer
                onClick={() => {
                  handleDelete(row.saved_search_id, row.name);
                }}
              >
                <Bin color={BLUE_LIGHT_HALEX} size={25} />
              </BinContainer>
            </WrapperData>
          );
        })}
      </ContainerDataSaved>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 40px 70px 0 70px;
`;

const WrapperTab = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const ButtonTab = styled.div<{active: boolean}>`
  display: flex;
  flex-direction: row;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: solid 4px ${({active}) => (active ? BLUE_HALEX_MAIN : 'transparent')};
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${({active, theme}) => (active ? theme.table.colors.headerRow : LIGHT_GREY_1)};
`;

const WrapperNumber = styled.div<{active: boolean}>`
  display: flex;
  background: ${({active}) => (active ? BLUE_HALEX_MAIN : LIGHT_GREY_1)};
  width: 40px;
  height: 25px;
  border-radius: 8px;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
`;

const Line = styled.div`
  border: 1px solid #c6c9d2;
`;

const BinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${BLUE_LIGHT_HALEX};
  border-radius: ${BORDER_RADIUS}px;
  opacity: 0.5;
`;

const ContainerDataSaved = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 16px;
  background-color: ${({theme}) => theme.colors.background};
  border: 1px solid rgb(198, 201, 210);
`;

const HeaderSaved = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #84b8f5;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  text-align: left;
  font-size: 15px;
  line-height: 18px;
  height: 40px;
  background-color: ${({theme}) => theme.table.colors.headerRow};
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  padding-left: 15px;
  align-items: center;
`;

const WrapperData = styled(HeaderSaved)`
  background-color: ${({theme}) => theme.colors.background};
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :nth-child(odd) {
    background-color: rgba(198, 201, 210, 0.1);
  }
  &:hover {
    background-color: rgba(65, 136, 219, 0.1) !important;
  }
`;

const Name = styled(Column)`
  color: ${({theme}) => (theme.name === 'light' ? BLACK : WHITE)};
`;

const DateDisplay = styled(Column)`
  flex-direction: row;
  justify-content: space-between;
  color: ${BLUE_LIGHT_HALEX};
  font-weight: 400;
`;
