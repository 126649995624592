import {
  BACKGROUND_DARK,
  BLACK,
  BLUE_3_SECONDARY,
  BORDER_RADIUS,
  BORDER_WIDTH,
  GREY_2,
  LIGHT_GREY_1,
  LIGHT_GREY_2,
  SHADOW_HALEX,
  WHITE,
} from '@devipsendigital/react-ui-kit';
import {DefaultTheme} from 'styled-components';

import {
  BLUE_HALEX_MAIN,
  BLUE_LIGHT_HALEX,
  BLUE_BUTTON_LIGHT,
  BLUE_BUTTON_LIGHT_HOVER_LIGHT,
  BLUE_LIGHT_HALEX_LIGHT,
  DARK_GREY_1,
} from '../colors';

export const LIGHT_THEME: DefaultTheme = {
  name: 'light',
  borderRadius: BORDER_RADIUS,
  borderWidth: BORDER_WIDTH,
  fontBase: 'Roboto',
  colors: {
    background: LIGHT_GREY_2,
    textColor: BLACK,
    headerBackground: WHITE,
    logo: BLACK,
  },
  button: {
    colors: {
      normal: BLUE_HALEX_MAIN,
      hover: BLUE_LIGHT_HALEX,
      disabled: LIGHT_GREY_1,
      textColor: GREY_2,
    },
  },
  switch: {
    toggle: {
      colors: {
        normal: BLUE_BUTTON_LIGHT,
      },
    },
  },
  inputChip: {
    colors: {
      normal: BLUE_3_SECONDARY,
    },
  },
  inputSelect: {
    colors: {
      background: WHITE,
    },
    control: {
      colors: {
        boxShadow: LIGHT_GREY_1,
      },
    },
    input: {
      colors: {
        textColor: GREY_2,
      },
    },
  },
  step: {
    colors: {
      active: BLUE_3_SECONDARY,
      disabled: LIGHT_GREY_1,
    },
  },
  selectableList: {
    autocomplete: {
      checkbox: {
        checkedBackground: BLUE_LIGHT_HALEX,
        unChecked: LIGHT_GREY_1,
      },
      hoverColor: BLUE_LIGHT_HALEX_LIGHT,
    },
    containerText: {
      background: WHITE,
      shadow: SHADOW_HALEX,
      textColor: DARK_GREY_1,
    },
  },
  radio: {
    colors: {
      normal: LIGHT_GREY_1,
      hover: BLUE_3_SECONDARY,
      background: WHITE,
      textColor: BACKGROUND_DARK,
    },
  },
  table: {
    colors: {
      background: WHITE,
      border: LIGHT_GREY_1,
      hoveredRow: BLUE_BUTTON_LIGHT_HOVER_LIGHT,
      headerRow: BLUE_LIGHT_HALEX,
      leftArrowButton: LIGHT_GREY_1,
      leftArrowButtonPrevious: BLUE_BUTTON_LIGHT,
      rightArrowButton: LIGHT_GREY_1,
      rightArrowButtonPrevious: BLUE_BUTTON_LIGHT,
      displayPage: BLUE_LIGHT_HALEX,
    },
    header: {
      cells: {
        background: BLUE_LIGHT_HALEX,
      },
    },
  },
  typography: {
    p1: {
      colors: {
        textColor: GREY_2,
      },
    },
    p2: {
      colors: {
        textColor: LIGHT_GREY_1,
      },
    },
    p3: {
      colors: {
        textColor: LIGHT_GREY_1,
      },
    },
    h1: {
      colors: {
        textColor: BLUE_3_SECONDARY,
      },
    },
    h2: {
      colors: {
        textColor: BLUE_3_SECONDARY,
      },
    },
  },
};
